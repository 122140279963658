import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Menu,
  Home,
  MapPin,
  Box,
  GitBranch,
  Map,
  Settings,
  ChevronDown,
  User,
  LogOut,
} from "lucide-react";
import { Button } from "../ui/button";
import { useUser, useClerk } from "@clerk/clerk-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

interface NavigationProps {
  isAuthPage: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ isAuthPage }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, isLoaded: isUserLoaded } = useUser();
  const { signOut } = useClerk();
  const location = useLocation();

  const handleSignOut = () => {
    signOut();
  };

  const navItems = [
    { path: "/", icon: Home, label: "Dashboard" },
    { path: "/locations", icon: MapPin, label: "Locations" },
    { path: "/assets", icon: Box, label: "Assets" },
    { path: "/workflows", icon: GitBranch, label: "Workflows" },
    { path: "/map", icon: Map, label: "Map" },
    { path: "/admin", icon: Settings, label: "Admin" },
  ];

  return (
    <>
      <nav className="bg-white text-black p-4 flex justify-between items-center border-b border-gray-300 fixed top-0 left-0 right-0 z-50">
        <div className="flex items-center">
          {!isAuthPage && (
            <Button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              variant="ghost"
              className="p-0 mr-8"
            >
              <Menu className="h-6 w-6" />
            </Button>
          )}
          <img src="/fieldbase.png" alt="Fieldbase Logo" className="h-8" />
        </div>
        {isUserLoaded && user && !isAuthPage && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="flex items-center space-x-2">
                <span>{user.fullName}</span>
                <ChevronDown size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-white" align="end">
              <DropdownMenuItem asChild>
                <Link to="/profile" className="w-full flex items-center">
                  <User className="mr-2 h-4 w-4" />
                  <span>Profile</span>
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleSignOut}
                className="flex items-center"
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </nav>

      {isUserLoaded && user && !isAuthPage && (
        <aside
          className={`fixed left-0 top-[65px] bottom-0 bg-white border-r border-gray-300 overflow-y-auto transition-all duration-400 ${
            isSidebarOpen ? "w-64" : "w-14"
          }`}
        >
          <div className="flex flex-col h-full py-4">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`flex items-center px-4 py-2 text-sm ${
                  location.pathname === item.path
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <item.icon className="h-5 w-5" />
                {isSidebarOpen && <span className="ml-3">{item.label}</span>}
              </Link>
            ))}
          </div>
        </aside>
      )}
    </>
  );
};

export default Navigation;
