import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Info, Wrench, Lightbulb, Edit, Save, Pen, MapPin } from "lucide-react";
import ChatBar from "../../common/ChatBar";
import CheckInOutComponent from "../../common/CheckInOutComponent";
import { Button } from "../../ui/button";
import PhotoGallery from "../../common/PhotoGallery";
import VideoGallery from "../../common/VideoGallery";
import FullSizeImageView from "../../common/FullSizeImageView";
import DocumentsGrid from "../../common/DocumentsGrid";
import ChecklistsGrid from "../../common/ChecklistsGrid";
import ChecklistCreationDialog from "../../common/ChecklistCreationDialog";
import ChecklistCompletionDialog from "../../common/ChecklistCompletionDialog";
import MaintenanceNotesSection from "../../common/MaintenanceNotesSection";
import SolutionsSection from "../../common/SolutionsSection";
import AssetInfoSection from "../../common/AssetInfoSection";
import ImageAnnotator from "../../common/ImageAnnotator";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { Input } from "../../ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";

import AzureMap from "../../common/AzureMap";

import { useAssetApiClient } from "../../../services/api/assetApiClient";
import { Asset as AssetInterface } from "../../../interfaces/asset";

// data
import assetData from "../../../data/assets/assets.json";

const USE_MOCK = false;

interface Image {
  id: number;
  filename: string;
  description: string;
  locked: boolean;
}

interface NewImage {
  id: number;
  url: string;
  description: string;
  locked: boolean;
}

interface Video {
  id: number;
  filename: string;
  description: string;
  locked: boolean;
  transcript?: string;
  summary?: string;
}

interface NewVideo {
  id: number;
  url: string;
  description: string;
  locked: boolean;
  transcript?: string;
  summary?: string;
}

interface CompletedChecklist {
  id: number;
  checklistId: number;
  name: string;
  completedBy: string;
  completedAt: string;
  answers: boolean[];
}

interface AssetDetail {
  assetId: number;
  name: string;
  location: string;
  locationId: number;
  lastServiceDate: string;
  lastServiceBy: string;
  type: string;
  group: string;
  documents?: { id: number; name: string; path: string }[];
  images?: Image[];
  videos?: Video[];
  maintenanceNotes?: {
    id: number;
    date: string;
    technician: string;
    note: string;
  }[];
  solutions?: {
    id: number;
    problem: string;
    solution: string;
    status: string;
  }[];
  checklistItems?: { id: number; name: string; questions: string[] }[];
}

const Asset: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [asset, setAsset] = useState<AssetDetail | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState("");
  const [newImages, setNewImages] = useState<NewImage[]>([]);
  const [newVideos, setNewVideos] = useState<NewVideo[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [documentSasUrls, setDocumentSasUrls] = useState<{
    [key: string]: string;
  }>({});
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [videoUrls, setVideoUrls] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(
    null
  );
  const [showMaintenanceNotes, setShowMaintenanceNotes] = useState(false);
  const [showSolutions, setShowSolutions] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [showAddMaintenanceNote, setShowAddMaintenanceNote] = useState(false);
  const [showAddSolution, setShowAddSolution] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showChatBar, setShowChatBar] = useState(true);
  const [selectedChecklist, setSelectedChecklist] = useState<{
    id: number;
    name: string;
    questions: string[];
  } | null>(null);

  const [isChecklistCompletionDialogOpen, setIsChecklistCompletionDialogOpen] =
    useState(false);

  const [completedChecklists, setCompletedChecklists] = useState<
    CompletedChecklist[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [showCoordinatesDialog, setShowCoordinatesDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [tempCoordinates, setTempCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [coordinates, setCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoThumbnailUrls, setVideoThumbnailUrls] = useState<string[]>([]);
  const [isChecklistDialogOpen, setIsChecklistDialogOpen] = useState(false);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  const [isAnnotatorOpen, setIsAnnotatorOpen] = useState(false);
  const [selectedImageForAnnotation, setSelectedImageForAnnotation] = useState<
    number | null
  >(null);

  const { user } = useUser();
  const [userInfo, setUserInfo] = useState<{
    id: string | null;
    fullName: string | null;
    username: string | null;
  } | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const sortedCompletedChecklists = completedChecklists.sort(
    (a, b) =>
      new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentChecklists = sortedCompletedChecklists.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(sortedCompletedChecklists.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  useEffect(() => {
    console.log("Current user from Clerk:", user);
    if (user) {
      setUserInfo({
        id: user.id,
        fullName: user.fullName,
        username: user.username,
      });
    } else {
      setUserInfo(null);
    }
  }, [user]);

  useEffect(() => {
    if (asset && asset.completedChecklists) {
      setCompletedChecklists(asset.completedChecklists);
    }
  }, [asset]);

  const navigate = useNavigate();
  const assetApiClient = useAssetApiClient();

  const refreshImageUrls = useCallback(async () => {
    if (!asset?.images) return;

    try {
      console.log("Refreshing image URLs for", asset.images.length, "images");

      const updatedImages = await Promise.all(
        asset.images.map(async (img) => {
          if (!img) return null;
          try {
            const url = await assetApiClient.getFileSasToken(
              asset.assetId.toString(),
              img.blobName
            );
            console.log(`Got new URL for image ${img.id}`);
            return { ...img, url };
          } catch (error) {
            console.error(`Error fetching SAS URL for image ${img.id}:`, error);
            return img;
          }
        })
      );

      const filteredImages = updatedImages.filter(Boolean) as Image[];
      console.log("Setting asset with updated images:", filteredImages.length);

      setAsset((prevAsset) => {
        if (!prevAsset) return prevAsset;
        return {
          ...prevAsset,
          images: filteredImages,
        };
      });

      // Also update the imageUrls array for the FullSizeImageView
      const newUrls = filteredImages
        .map((img) => img.url)
        .filter(Boolean) as string[];
      setImageUrls(newUrls);
    } catch (error) {
      console.error("Error in refreshImageUrls:", error);
    }
  }, [asset, assetApiClient]);

  const handlePhotoUpload = useCallback(
    async (updatedAsset: AssetDetail) => {
      try {
        console.log("Handling photo upload with updated asset:", updatedAsset);
        setAsset(updatedAsset); // Update the main asset state

        // Wait for asset state to update
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Then refresh URLs for the new image
        const urls = await Promise.all(
          updatedAsset.images?.map(async (img) => {
            try {
              return await assetApiClient.getFileSasToken(
                updatedAsset.assetId.toString(),
                img.blobName
              );
            } catch (error) {
              console.error(
                `Error fetching SAS URL for image ${img.id}:`,
                error
              );
              return "";
            }
          }) || []
        );

        // Update the image URLs array
        const filteredUrls = urls.filter((url) => url !== "");
        setImageUrls(filteredUrls);

        // Update the asset's images with URLs
        setAsset((prevAsset) => {
          if (!prevAsset) return updatedAsset;
          return {
            ...prevAsset,
            images: prevAsset.images?.map((img, index) => ({
              ...img,
              url: filteredUrls[index] || img.url,
            })),
          };
        });

        console.log("Photo upload and refresh complete");
      } catch (error) {
        console.error("Error in handlePhotoUpload:", error);
      }
    },
    [assetApiClient]
  );

  const handleAddNewChecklist = () => {
    setIsChecklistDialogOpen(true);
  };

  const handleChecklistSelect = (checklist: ChecklistItem) => {
    console.log("Checklist selected:", checklist);
    setSelectedChecklist(checklist);
    setIsChecklistCompletionDialogOpen(true);
  };

  const handleSaveCompletedChecklist = async (
    completedChecklist: CompletedChecklist
  ) => {
    console.log("Handling save of completed checklist:", completedChecklist);
    if (asset && id && userInfo) {
      try {
        console.log("Calling API to save completed checklist");
        const updatedAsset = await assetApiClient.addCompletedChecklistToAsset(
          parseInt(id),
          {
            ...completedChecklist,
            completedBy:
              userInfo.fullName ||
              userInfo.username ||
              userInfo.id ||
              "Anonymous User",
          }
        );
        console.log("API call successful, updated asset:", updatedAsset);
        setAsset(updatedAsset);
        if (updatedAsset.completedChecklists) {
          setCompletedChecklists(updatedAsset.completedChecklists);
        }
      } catch (error) {
        console.error("Error saving completed checklist:", error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      console.log(
        "Asset, id, or userInfo is null, cannot save completed checklist"
      );
    }
  };

  const handleSaveChecklist = async (name: string, items: string[]) => {
    if (asset && id) {
      try {
        const newChecklist = {
          id: Date.now(),
          name,
          questions: items,
        };
        const updatedAsset = await assetApiClient.addChecklistToAsset(
          parseInt(id),
          newChecklist
        );
        setAsset(updatedAsset);
      } catch (error) {
        console.error("Error saving checklist:", error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const fetchVideoThumbnailUrls = useCallback(async () => {
    if (!asset || !asset.videos) {
      setVideoThumbnailUrls([]);
      return;
    }

    const urls = await Promise.all(
      asset.videos.map(async (video) => {
        try {
          return await assetApiClient.getFileSasToken(
            asset.assetId.toString(),
            video.thumbnailBlobName
          );
        } catch (error) {
          console.error(
            `Error fetching SAS URL for video thumbnail ${video.id}:`,
            error
          );
          return "";
        }
      })
    );

    setVideoThumbnailUrls(urls.filter((url) => url !== ""));
  }, [asset, assetApiClient]);

  useEffect(() => {
    fetchVideoThumbnailUrls();
    // Set up an interval to refresh SAS tokens every 50 minutes
    const intervalId = setInterval(fetchVideoThumbnailUrls, 50 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [fetchVideoThumbnailUrls]);

  const handleRefreshVideoThumbnail = useCallback(
    async (index: number) => {
      if (!asset || !asset.videos) return "";

      const video = asset.videos[index];
      try {
        const newUrl = await assetApiClient.getFileSasToken(
          asset.assetId.toString(),
          video.thumbnailBlobName
        );
        const newVideoThumbnailUrls = [...videoThumbnailUrls];
        newVideoThumbnailUrls[index] = newUrl;
        setVideoThumbnailUrls(newVideoThumbnailUrls);
        return newUrl;
      } catch (error) {
        console.error(
          `Error refreshing SAS URL for video thumbnail ${video.id}:`,
          error
        );
        throw error;
      }
    },
    [asset, assetApiClient, videoThumbnailUrls]
  );

  const fetchImageUrls = useCallback(async () => {
    if (!asset || !asset.images) {
      setImageUrls([]);
      return;
    }

    const urls = await Promise.all(
      asset.images.map(async (img) => {
        try {
          return await assetApiClient.getFileSasToken(
            asset.assetId.toString(),
            img.blobName
          );
        } catch (error) {
          console.error(`Error fetching SAS URL for image ${img.id}:`, error);
          return "";
        }
      })
    );

    setImageUrls(urls.filter((url) => url !== ""));
  }, [asset, assetApiClient]);

  useEffect(() => {
    fetchImageUrls();
    // Set up an interval to refresh SAS tokens every 50 minutes
    const intervalId = setInterval(fetchImageUrls, 50 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [fetchImageUrls]);

  const handleRefreshImage = useCallback(
    async (index: number) => {
      if (!asset || !asset.images) return "";

      const img = asset.images[index];
      try {
        const newUrl = await assetApiClient.refreshSasToken(
          asset.assetId.toString(),
          img.blobName
        );
        const newImageUrls = [...imageUrls];
        newImageUrls[index] = newUrl;
        setImageUrls(newImageUrls);
        return newUrl;
      } catch (error) {
        console.error(`Error refreshing SAS URL for image ${img.id}:`, error);
        throw error;
      }
    },
    [asset, assetApiClient, imageUrls]
  );

  const refreshAllImageTokens = useCallback(async () => {
    if (!asset || !asset.images) {
      return [];
    }

    try {
      const newUrls = await Promise.all(
        asset.images.map(async (img) => {
          try {
            return await assetApiClient.refreshSasToken(
              asset.assetId.toString(),
              img.blobName
            );
          } catch (error) {
            console.error(
              `Error refreshing SAS token for image ${img.id}:`,
              error
            );
            return "";
          }
        })
      );

      setImageUrls(newUrls.filter((url) => url !== ""));
      return newUrls;
    } catch (error) {
      console.error("Error refreshing all image tokens:", error);
      return [];
    }
  }, [asset, assetApiClient]);

  const handleNextImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      if (prevIndex === null || prevIndex >= imageUrls.length - 1) {
        return prevIndex;
      }
      return prevIndex + 1;
    });
  }, [imageUrls.length]);

  const handlePreviousImage = useCallback(() => {
    setSelectedImageIndex((prevIndex) => {
      if (prevIndex === null || prevIndex <= 0) {
        return prevIndex;
      }
      return prevIndex - 1;
    });
  }, []);

  const memoizedApiFunctions = useMemo(() => {
    return {
      getAssetById: assetApiClient.getAssetById,
      updateAsset: assetApiClient.updateAsset,
      uploadFile: assetApiClient.uploadFile,
      getFileSasToken: assetApiClient.getFileSasToken,
      triggerDocumentIndexing: assetApiClient.triggerDocumentIndexing,
    };
  }, [assetApiClient]);

  useEffect(() => {
    if (asset && asset.coordinates) {
      setCoordinates(asset.coordinates);
    }
  }, [asset]);

  const handleMapPinClick = async () => {
    setIsLoading(true);
    if (!coordinates) {
      try {
        const position = await getCurrentPosition();
        const newCoordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setTempCoordinates(newCoordinates);
        setShowConfirmationDialog(true);
      } catch (error) {
        console.error("Error getting location:", error);
        // Handle error (e.g., show error message to user)
      }
    } else {
      setShowCoordinatesDialog(true);
    }
    setIsLoading(false);
  };

  const getCurrentPosition = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const handleConfirmCoordinates = async () => {
    if (tempCoordinates) {
      try {
        await updateAssetCoordinates(tempCoordinates);
        setCoordinates(tempCoordinates);
        setShowConfirmationDialog(false);
        setShowCoordinatesDialog(true);
      } catch (error) {
        console.error("Error updating asset coordinates:", error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const updateAssetCoordinates = async (newCoordinates: {
    latitude: number;
    longitude: number;
  }) => {
    if (asset && id) {
      try {
        const updatedAsset = await assetApiClient.updateAsset(parseInt(id), {
          coordinates: newCoordinates,
          name: asset.name,
          location: asset.location,
          locationId: asset.locationId,
          type: asset.type,
          group: asset.group,
        });

        if (updatedAsset) {
          setAsset(updatedAsset);
        }
      } catch (error) {
        console.error("Error updating asset coordinates:", error);
        // Handle error appropriately
        throw error;
      }
    }
  };

  const fetchAsset = useCallback(async () => {
    if (!id) return;

    try {
      setLoading(true);
      console.log("Fetching asset with id:", id);
      const fetchedAsset = await memoizedApiFunctions.getAssetById(
        parseInt(id)
      );
      if (fetchedAsset) {
        console.log("Asset fetched successfully:", fetchedAsset);
        setAsset(fetchedAsset);
        if (fetchedAsset.coordinates) {
          setCoordinates(fetchedAsset.coordinates);
        }
      } else {
        console.log("Asset not found");
        setError("Asset not found");
      }
    } catch (err) {
      console.error("Error fetching asset:", err);
      if (err instanceof Error) {
        setError(`Error fetching asset: ${err.message}`);
      } else {
        setError("An unknown error occurred while fetching the asset");
      }
    } finally {
      setLoading(false);
    }
  }, [id, memoizedApiFunctions]);

  useEffect(() => {
    fetchAsset();
  }, [fetchAsset]);

  useEffect(() => {
    if (asset && asset.documents) {
      fetchSasUrls(asset.documents);
    }
  }, [asset]);

  const handleDocumentUpload = useCallback(
    async (file: File) => {
      if (!asset) {
        console.error("Asset is not defined");
        setError("Asset not found. Unable to upload document.");
        return;
      }

      setUploadingDocument(true);
      setError(null);

      try {
        const formData = new FormData();
        formData.append("file", file);

        const updatedAsset = await assetApiClient.uploadFile(
          asset.assetId.toString(),
          formData
        );

        console.log("File uploaded successfully. Updated asset:", updatedAsset);
        setAsset(updatedAsset);

        // Handle successful upload (e.g., update UI, show success message)
      } catch (error) {
        console.error("Error uploading document:", error);
        setError("Failed to upload document. Please try again.");
      } finally {
        setUploadingDocument(false);
      }
    },
    [asset, assetApiClient]
  );

  const handleSaveName = async () => {
    if (asset && id && newName.trim() !== "") {
      try {
        console.log("Updating asset name:", { assetId: id, newName });
        const updatedAsset = await memoizedApiFunctions.updateAsset(
          parseInt(id),
          {
            name: newName.trim(),
          }
        );
        if (updatedAsset) {
          setAsset(updatedAsset);
          setIsEditingName(false);
        }
      } catch (err) {
        console.error("Error updating asset name:", err);
        setError("Failed to update asset name. Please try again.");
      }
    } else {
      setError("Please enter a valid name before saving.");
    }
  };

  const fetchSasUrls = useCallback(async () => {
    if (!asset) return;

    const newDocumentSasUrls: { [key: string]: string } = {};
    const newImageUrls: string[] = [];
    const newVideoUrls: string[] = [];

    // Fetch SAS URLs for documents
    if (asset.documents) {
      for (const doc of asset.documents) {
        try {
          const sasUrl = await assetApiClient.getFileSasToken(
            asset.assetId.toString(),
            doc.blobName
          );
          newDocumentSasUrls[doc.id] = sasUrl;
        } catch (error) {
          console.error(
            `Error fetching SAS URL for document ${doc.id}:`,
            error
          );
        }
      }
    }

    // Fetch SAS URLs for images
    if (asset.images) {
      for (const img of asset.images) {
        try {
          const sasUrl = await assetApiClient.getFileSasToken(
            asset.assetId.toString(),
            img.blobName
          );
          newImageUrls.push(sasUrl);
        } catch (error) {
          console.error(`Error fetching SAS URL for image ${img.id}:`, error);
        }
      }
    }

    // Fetch SAS URLs for videos
    if (asset.videos) {
      for (const video of asset.videos) {
        try {
          const sasUrl = await assetApiClient.getFileSasToken(
            asset.assetId.toString(),
            video.blobName
          );
          newVideoUrls.push(sasUrl);
        } catch (error) {
          console.error(`Error fetching SAS URL for video ${video.id}:`, error);
        }
      }
    }

    setDocumentSasUrls(newDocumentSasUrls);
    setImageUrls(newImageUrls);
    setVideoUrls(newVideoUrls);
  }, [asset, assetApiClient]);

  useEffect(() => {
    if (asset) {
      fetchSasUrls();
    }
  }, [asset, fetchSasUrls]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;
  // if (!asset) return <div>Asset not found</div>;

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-lg">Loading asset details...</div>
      </div>
    );
  }

  const handleVideoUpload = (updatedAsset: Asset) => {
    setAsset(updatedAsset);
  };

  const handleAnnotateImage = (index: number) => {
    if (asset?.images && asset.images[index]) {
      setSelectedImageForAnnotation(index);
      setIsAnnotatorOpen(true);
    } else {
      console.error("Invalid image index or image not found");
    }
  };

  const handleAddMaintenanceNote = async (noteText: string) => {
    if (asset && userInfo) {
      const newNote = {
        date: new Date().toISOString().split("T")[0],
        technician: userInfo.fullName || userInfo.username || "Unknown User",
        note: noteText,
      };

      try {
        const updatedAsset = await assetApiClient.addMaintenanceNote(
          asset.assetId.toString(),
          newNote
        );
        setAsset(updatedAsset);
        setShowAddMaintenanceNote(false);
      } catch (error) {
        console.error("Error adding maintenance note:", error);
        setError("Failed to add maintenance note. Please try again.");
      }
    }
  };

  const handleAddSolution = async (
    problemText: string,
    solutionText: string
  ) => {
    if (asset) {
      const newSolution = {
        id: Date.now(),
        problem: problemText,
        solution: solutionText,
        status: "Pending approval",
      };

      try {
        const updatedAsset = await assetApiClient.addSolutionToAsset(
          asset.assetId.toString(),
          newSolution
        );
        setAsset(updatedAsset);
      } catch (error) {
        console.error("Error adding solution:", error);
        setError("Failed to add solution. Please try again.");
      }
    }
    setShowAddSolution(false);
  };

  const handleRateSolution = async (
    solutionId: number,
    rating: "up" | "down",
    comment: string
  ) => {
    if (asset) {
      try {
        const updatedAsset = await assetApiClient.rateSolution(
          asset.assetId.toString(),
          solutionId,
          rating,
          comment
        );
        setAsset(updatedAsset);
      } catch (error) {
        console.error("Error rating solution:", error);
        setError("Failed to rate solution. Please try again.");
      }
    }
  };

  const handleAnnotationSave = async (imageBlob: Blob) => {
    if (!asset?.images || selectedImageForAnnotation === null) {
      console.error("No asset images or selected image index");
      return;
    }

    try {
      // Create a FormData object to send the image
      const formData = new FormData();
      formData.append("image", imageBlob, "annotated-image.jpg");

      console.log("Uploading annotated image:", {
        assetId: asset.assetId.toString(),
        imageIndex: selectedImageForAnnotation,
        blobSize: imageBlob.size,
      });

      // Upload the annotated image using the API client
      const updatedAsset = await assetApiClient.updateImage(
        asset.assetId.toString(),
        selectedImageForAnnotation,
        formData
      );

      if (updatedAsset) {
        console.log("Successfully updated asset with annotated image");
        setAsset(updatedAsset);

        // Refresh the image URLs after update
        await refreshImageUrls();
      }

      setIsAnnotatorOpen(false);
    } catch (error) {
      console.error("Error saving annotated image:", error);
      // Optionally show error to user here
    }
  };

  const allImages = [
    ...(asset.images?.map((img) => `/images/${img.filename}`) ?? []),
    ...newImages.map((img) => img.url),
  ];

  return (
    <div className="container mx-auto p-4 pb-20">
      <div className="flex items-center mb-4">
        {isEditingName ? (
          <>
            <Input
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="mr-2"
            />
            <Button onClick={handleSaveName} className="mr-2">
              <Save className="w-4 h-4 mr-2" />
              Save
            </Button>
            <Button onClick={() => setIsEditingName(false)} variant="outline">
              Cancel
            </Button>
          </>
        ) : (
          <div className="flex items-center">
            <h1 className="text-2xl font-bold mr-2">{asset.name}</h1>
            <Button
              onClick={handleMapPinClick}
              variant="ghost"
              size="lg"
              className="p-1"
            >
              <MapPin
                className={`w-7 h-7 ${
                  coordinates ? "text-blue-500" : "text-gray-500"
                }`}
              />
              <span className="sr-only">Location</span>
            </Button>
            {/* <Button
              onClick={() => setIsEditingName(true)}
              variant="ghost"
              size="sm"
              className="p-1"
            >
              <Pen className="w-4 h-4 text-gray-500" />
              <span className="sr-only">Edit name</span>
            </Button> */}
          </div>
        )}
      </div>

      {asset.group === "tool" || asset.group === "vehicle" ? (
        <CheckInOutComponent name={asset.name} type="asset" />
      ) : (
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-4">
            <Button
              onClick={() => setShowInfo(!showInfo)}
              className="flex items-center justify-center border border-black w-full sm:w-auto"
              variant="outline"
            >
              <Info className="mr-2" />
              Info
            </Button>
            <Button
              onClick={() => setShowMaintenanceNotes(!showMaintenanceNotes)}
              className="flex items-center justify-center border border-black w-full sm:w-auto relative"
              variant="outline"
            >
              <Wrench className="mr-2" />
              Maintenance Notes
              {asset.maintenanceNotes && asset.maintenanceNotes.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                  {asset.maintenanceNotes.length}
                </span>
              )}
            </Button>
            <Button
              onClick={() => setShowSolutions(!showSolutions)}
              className="flex items-center justify-center border border-black w-full sm:w-auto relative"
              variant="outline"
            >
              <Lightbulb className="mr-2" />
              Solutions
              {asset.solutions && asset.solutions.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                  {asset.solutions.length}
                </span>
              )}
            </Button>
          </div>
          <AssetInfoSection
            showInfo={showInfo}
            location={asset.location}
            lastServiceDate={asset.lastServiceDate}
            lastServiceBy={asset.lastServiceBy}
            group={asset.group}
          />
          <MaintenanceNotesSection
            showMaintenanceNotes={showMaintenanceNotes}
            maintenanceNotes={asset.maintenanceNotes || []}
            showAddMaintenanceNote={showAddMaintenanceNote}
            onAddMaintenanceNote={() => setShowAddMaintenanceNote(true)}
            onSubmitMaintenanceNote={handleAddMaintenanceNote}
            onCancelAddMaintenanceNote={() => setShowAddMaintenanceNote(false)}
          />
          <SolutionsSection
            showSolutions={showSolutions}
            solutions={asset.solutions || []}
            showAddSolution={showAddSolution}
            onAddSolution={() => setShowAddSolution(true)}
            onSubmitSolution={handleAddSolution}
            onCancelAddSolution={() => setShowAddSolution(false)}
            onRateSolution={handleRateSolution}
          />
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <div className="bg-white p-4 rounded-lg mb-6 shadow-md">
            <DocumentsGrid
              documents={asset.documents || []}
              onDocumentUpload={handleDocumentUpload}
              isUploading={uploadingDocument}
              sasUrls={documentSasUrls}
            />
          </div>

          <div className="bg-white p-4 rounded-lg mb-6 shadow-md">
            <ChecklistsGrid
              checklistItems={asset.checklistItems || []}
              onChecklistSelect={handleChecklistSelect}
              onAddNewChecklist={handleAddNewChecklist}
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">
              Completed Checklists ({sortedCompletedChecklists.length})
            </h3>
            {sortedCompletedChecklists.length > 0 ? (
              <>
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Checklist Name</TableHead>
                        <TableHead>Completed By</TableHead>
                        <TableHead>Completed At</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {currentChecklists.map((checklist) => (
                        <TableRow key={checklist.id}>
                          <TableCell>{checklist.name}</TableCell>
                          <TableCell>{checklist.completedBy}</TableCell>
                          <TableCell>
                            {new Date(checklist.completedAt).toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="flex justify-between items-center mt-4 bg-gray-100">
                  <Button onClick={prevPage} disabled={currentPage === 1}>
                    Previous
                  </Button>
                  <span>{/* Page {currentPage} of {totalPages} */}</span>
                  <Button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : (
              <p>No completed checklists yet.</p>
            )}
          </div>
        </div>
        <div>
          <div className="bg-white p-4 rounded-lg mb-6 shadow-md">
            <PhotoGallery
              images={asset?.images || []}
              assetId={asset?.assetId?.toString() || ""}
              onPhotoSelect={setSelectedImageIndex}
              onAnnotateImage={handleAnnotateImage}
              onRefreshTokens={refreshImageUrls}
              onPhotoUpload={handlePhotoUpload}
              isUploading={isUploadingPhoto}
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md">
            <VideoGallery
              videos={asset.videos || []}
              assetId={asset.assetId}
              thumbnailUrls={videoThumbnailUrls}
              onVideoSelect={setSelectedVideoIndex}
              onVideoUpload={handleVideoUpload}
              onRefreshThumbnail={handleRefreshVideoThumbnail}
            />
          </div>
          {USE_MOCK && (
            <p className="text-sm text-gray-500 mt-2">
              Note: Currently using mocked video upload and transcription.
            </p>
          )}
        </div>
      </div>

      <FullSizeImageView
        images={imageUrls}
        selectedIndex={selectedImageIndex}
        onClose={() => setSelectedImageIndex(null)}
        onNext={handleNextImage}
        onPrevious={handlePreviousImage}
      />

      <ChatBar
        title="Field Assistant"
        placeholder="Ask a question about this asset"
        assetId={asset.assetId.toString()}
      />

      <Dialog
        open={showConfirmationDialog}
        onOpenChange={setShowConfirmationDialog}
      >
        <DialogContent className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              Confirm Asset Location
            </DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Is the asset located at your current position?
          </DialogDescription>
          <DialogFooter>
            <Button
              onClick={() => setShowConfirmationDialog(false)}
              variant="outline"
            >
              No
            </Button>
            <Button
              onClick={handleConfirmCoordinates}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Yes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showCoordinatesDialog}
        onOpenChange={setShowCoordinatesDialog}
      >
        <DialogContent className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              Asset Location
            </DialogTitle>
          </DialogHeader>
          {coordinates ? (
            <div className="mt-4">
              <div className="h-88 w-full">
                <AzureMap
                  latitude={coordinates.latitude}
                  longitude={coordinates.longitude}
                  pinColor="blue"
                  title=""
                />
              </div>
            </div>
          ) : (
            <p>No coordinates available for this asset.</p>
          )}
        </DialogContent>
      </Dialog>

      <ChecklistCreationDialog
        isOpen={isChecklistDialogOpen}
        onClose={() => setIsChecklistDialogOpen(false)}
        onSave={handleSaveChecklist}
      />

      <ChecklistCompletionDialog
        isOpen={isChecklistCompletionDialogOpen}
        onClose={() => setIsChecklistCompletionDialogOpen(false)}
        checklist={selectedChecklist}
        onSave={handleSaveCompletedChecklist}
        userInfo={userInfo}
      />

      <Dialog open={isAnnotatorOpen} onOpenChange={setIsAnnotatorOpen}>
        <DialogContent className="max-w-[90vw] w-full h-[90vh] p-4 flex flex-col bg-white">
          <DialogHeader>
            <DialogTitle>Image Annotation</DialogTitle>
          </DialogHeader>
          <div className="flex-grow overflow-hidden">
            {selectedImageForAnnotation !== null &&
              asset?.images?.[selectedImageForAnnotation]?.url && (
                <ImageAnnotator
                  imageSrc={asset.images[selectedImageForAnnotation].url}
                  onSave={async (blob) => {
                    try {
                      await handleAnnotationSave(blob);
                      // Don't close the dialog here - let handleAnnotationSave do it
                    } catch (error) {
                      console.error("Failed to save annotation:", error);
                    }
                  }}
                  onCancel={() => setIsAnnotatorOpen(false)}
                  onImageLoadError={async () => {
                    if (selectedImageForAnnotation !== null) {
                      try {
                        return await handleRefreshImage(
                          selectedImageForAnnotation
                        );
                      } catch (error) {
                        console.error("Error refreshing image:", error);
                      }
                    }
                  }}
                />
              )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Asset;
