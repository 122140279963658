import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Mic } from "lucide-react";
import styles from "./SpeechToText.module.css";

interface SpeechToTextProps {
  onTranscript: (transcript: string) => void;
  onListeningChange?: (isListening: boolean) => void;
  placeholder?: string;
  buttonClass?: string;
  textareaClass?: string;
  initialText?: string;
}

export interface SpeechToTextRef {
  stopListening: () => void;
}

const SpeechToText = forwardRef<SpeechToTextRef, SpeechToTextProps>(
  (
    {
      onTranscript,
      onListeningChange,
      placeholder = "Speak or type your text here",
      buttonClass = "",
      textareaClass = "",
      initialText = "",
    },
    ref
  ) => {
    const [isListening, setIsListening] = useState(false);
    const [text, setText] = useState(initialText);
    const recognitionRef = useRef<any>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const interimTranscriptRef = useRef<string>("");

    useEffect(() => {
      setText(initialText);
    }, [initialText]);

    const updateTranscript = useCallback(
      (newText: string) => {
        setText(newText);
        onTranscript(newText);
        // Scroll to bottom of textarea
        if (textareaRef.current) {
          textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
      },
      [onTranscript]
    );

    const stopListening = useCallback(() => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setIsListening(false);
      interimTranscriptRef.current = "";
    }, []);

    useImperativeHandle(ref, () => ({
      stopListening,
    }));

    useEffect(() => {
      return () => {
        if (recognitionRef.current) {
          recognitionRef.current.stop();
        }
      };
    }, []);

    useEffect(() => {
      if (onListeningChange) {
        onListeningChange(isListening);
      }
    }, [isListening, onListeningChange]);

    const handleSpeechRecognition = () => {
      if ("webkitSpeechRecognition" in window) {
        if (isListening) {
          stopListening();
          return;
        }

        recognitionRef.current = new (window as any).webkitSpeechRecognition();
        const recognition = recognitionRef.current;

        recognition.continuous = true;
        recognition.interimResults = true;

        recognition.onstart = () => {
          setIsListening(true);
          interimTranscriptRef.current = "";
        };

        recognition.onresult = (event: any) => {
          let finalTranscript = "";
          let interimTranscript = "";

          // Process results
          for (let i = event.resultIndex; i < event.results.length; ++i) {
            const transcript = event.results[i][0].transcript;
            if (event.results[i].isFinal) {
              finalTranscript += transcript;
            } else {
              interimTranscript += transcript;
            }
          }

          // Update only if we have final results or new interim results
          if (finalTranscript) {
            // Add final transcript to existing text
            const newText = (text + " " + finalTranscript).trim();
            updateTranscript(newText);
            interimTranscriptRef.current = interimTranscript;
          } else if (interimTranscript !== interimTranscriptRef.current) {
            // Show interim results in the textarea
            const baseText = text.trim();
            const displayText = baseText
              ? baseText + " " + interimTranscript
              : interimTranscript;
            setText(displayText);
            interimTranscriptRef.current = interimTranscript;
          }
        };

        recognition.onerror = (event: any) => {
          console.error("Speech recognition error", event.error);
          stopListening();
        };

        recognition.onend = () => {
          if (isListening) {
            recognition.start();
          }
        };

        recognition.start();
      } else {
        alert("Speech recognition is not supported in this browser.");
      }
    };

    return (
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={text}
          onChange={(e) => updateTranscript(e.target.value)}
          placeholder={placeholder}
          className={`pr-10 ${textareaClass}`}
          rows={4}
        />
        <button
          onClick={handleSpeechRecognition}
          className={`absolute right-2 top-2 ${buttonClass}`}
          type="button"
        >
          <Mic
            size={20}
            className={`${isListening ? styles.blinkingMic : ""}`}
          />
        </button>
      </div>
    );
  }
);

export default SpeechToText;
