import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAssetApiClient } from "../../../services/api/assetApiClient";
import { Asset } from "../../../interfaces/asset";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import AddAssetDialog from "../../common/AddAssetDialog";

const Assets: React.FC = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const assetApiClient = useAssetApiClient();
  const [isAddAssetDialogOpen, setIsAddAssetDialogOpen] = useState(false);

  const fetchAssets = async () => {
    try {
      setLoading(true);
      const response = await assetApiClient.getAllAssets();

      if (Array.isArray(response)) {
        setAssets(response);
      } else {
        console.error("Unexpected API response format:", response);
        setError("Received unexpected data format from the server.");
      }
    } catch (err) {
      console.error("Error fetching assets:", err);
      setError(
        "Failed to fetch assets. Please check the console for more details."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  const filteredAssets = assets.filter((asset) =>
    asset.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-lg">Loading assets...</div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold mb-4">Assets</h1>
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white"
          onClick={() => setIsAddAssetDialogOpen(true)}
        >
          Add Asset
        </Button>
      </div>

      <div className="mb-4">
        <div className="mb-4 flex space-x-2">
          <Input
            type="text"
            placeholder="Search assets..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
          <Button onClick={() => setSearchTerm("")}>Clear</Button>
        </div>
      </div>

      {assets.length === 0 ? (
        <p>No assets found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredAssets.map((asset) => (
            <Link
              to={`/asset/${asset.assetId}`}
              key={asset.assetId}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <div className="p-4">
                <h3 className="text-lg font-semibold">{asset.name}</h3>
                <p className="text-sm text-gray-600">{asset.location}</p>
              </div>
            </Link>
          ))}
        </div>
      )}

      <AddAssetDialog
        isOpen={isAddAssetDialogOpen}
        onClose={() => setIsAddAssetDialogOpen(false)}
        onAssetAdded={fetchAssets}
      />
    </div>
  );
};

export default Assets;
