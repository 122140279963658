import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Import the data
import workflowTemplates from "../../../data/workflows/workflows.json";
import workflowProgressData from "../../../data/workflows/workflowProgress.json";
import availableActions from "../../../data/shared/availableActions.json";

interface WorkflowTemplate {
  id: number;
  name: string;
  description: string;
  steps: {
    id: number;
    assignee: string;
    tasks: number[];
  }[];
}

interface WorkflowProgress {
  id: number;
  workflowId: number;
  name: string;
  currentStep: number;
  steps: {
    id: number;
    assignee: string;
    status: string;
    data: Record<string, string>;
  }[];
}

interface Action {
  id: number;
  name: string;
  type: string;
  options?: string[];
}

const WorkflowProgress: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [workflowProgress, setWorkflowProgress] =
    useState<WorkflowProgress | null>(null);
  const [workflowTemplate, setWorkflowTemplate] =
    useState<WorkflowTemplate | null>(null);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [isStepCompleted, setIsStepCompleted] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(1);

  useEffect(() => {
    const progress = workflowProgressData.find((w) => w.id === Number(id));
    if (progress) {
      setWorkflowProgress(progress);
      setActiveStep(progress.currentStep);
      const template = workflowTemplates.find(
        (t) => t.id === progress.workflowId
      );
      if (template) {
        setWorkflowTemplate(template);
      }
    }
  }, [id]);

  useEffect(() => {
    if (workflowProgress) {
      const currentStepData =
        workflowProgress.steps.find((s) => s.id === activeStep)?.data || {};
      setFormData(currentStepData);
      checkStepCompletion(currentStepData);
    }
  }, [workflowProgress, activeStep]);

  const handleInputChange = (actionId: number, value: string) => {
    const newFormData = { ...formData, [actionId]: value };
    setFormData(newFormData);
    checkStepCompletion(newFormData);
  };

  const checkStepCompletion = (data: Record<string, string>) => {
    if (workflowTemplate) {
      const currentTemplateStep = workflowTemplate.steps.find(
        (s) => s.id === activeStep
      );
      const allFieldsFilled = currentTemplateStep?.tasks.every(
        (actionId) => data[actionId] && data[actionId].trim() !== ""
      );
      setIsStepCompleted(!!allFieldsFilled);
    }
  };

  const handleSubmit = () => {
    if (workflowProgress && workflowTemplate) {
      const updatedSteps = workflowProgress.steps.map((step) =>
        step.id === activeStep
          ? { ...step, status: "completed", data: formData }
          : step
      );

      const nextStepId = activeStep + 1;
      const nextStep = updatedSteps.find((step) => step.id === nextStepId);

      if (nextStep) {
        nextStep.status = "in-progress";
      }

      const updatedWorkflowProgress = {
        ...workflowProgress,
        currentStep: nextStep ? nextStepId : workflowProgress.currentStep,
        steps: updatedSteps,
      };

      setWorkflowProgress(updatedWorkflowProgress);
      setActiveStep(nextStep ? nextStepId : workflowProgress.currentStep);
      setFormData({});
      setIsStepCompleted(false);

      console.log("Updated workflow progress:", updatedWorkflowProgress);
      // Here you would typically send this data to your backend
      alert("Step completed successfully!");
    }
  };

  const handleStepClick = (stepId: number) => {
    if (stepId <= workflowProgress!.currentStep) {
      setActiveStep(stepId);
    }
  };

  if (!workflowProgress || !workflowTemplate) {
    return <div>Loading...</div>;
  }

  const currentStep = workflowTemplate.steps.find((s) => s.id === activeStep);
  if (!currentStep) {
    return <div>Current step not found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{workflowProgress.name}</h1>

      {/* Progress Timeline */}
      <div className="flex mb-6 overflow-x-auto">
        {workflowTemplate.steps.map((step, index) => (
          <div
            key={step.id}
            className={`flex items-center cursor-pointer ${
              index === workflowTemplate.steps.length - 1 ? "" : "mr-2"
            }`}
            onClick={() => handleStepClick(step.id)}
          >
            <div
              className={`rounded-full w-8 h-8 flex items-center justify-center ${
                step.id < workflowProgress.currentStep
                  ? "bg-green-500"
                  : step.id === workflowProgress.currentStep
                  ? "bg-blue-500"
                  : "bg-gray-300"
              } ${
                step.id <= workflowProgress.currentStep
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              } text-white`}
            >
              {step.id < workflowProgress.currentStep ? "✓" : step.id}
            </div>
            <span
              className={`mx-2 ${activeStep === step.id ? "font-bold" : ""}`}
            >
              {step.assignee}
            </span>
            {index < workflowTemplate.steps.length - 1 && (
              <span className="mx-2">→</span>
            )}
          </div>
        ))}
      </div>

      <h2 className="text-xl font-semibold mb-2">
        Current Step: {currentStep.assignee}
      </h2>
      <p className="mb-4">
        Step {activeStep} of {workflowTemplate.steps.length}
      </p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="space-y-4"
      >
        {currentStep.tasks.map((actionId) => {
          const action = availableActions.find(
            (a: Action) => a.id === actionId
          );
          if (!action) return null;

          const isReadOnly = activeStep > workflowProgress.currentStep;

          switch (action.type) {
            case "select":
              return (
                <div key={action.id}>
                  <label className="block mb-2 font-bold">{action.name}</label>
                  <select
                    value={formData[action.id] || ""}
                    onChange={(e) =>
                      handleInputChange(action.id, e.target.value)
                    }
                    className="w-full p-2 border rounded"
                    disabled={isReadOnly}
                  >
                    <option value="">Select an option</option>
                    {action.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              );
            case "textarea":
              return (
                <div key={action.id}>
                  <label className="block mb-2 font-bold">{action.name}</label>
                  <textarea
                    value={formData[action.id] || ""}
                    onChange={(e) =>
                      handleInputChange(action.id, e.target.value)
                    }
                    className="w-full p-2 border rounded"
                    rows={4}
                    readOnly={isReadOnly}
                  />
                </div>
              );
            default:
              return null;
          }
        })}

        {activeStep === workflowProgress.currentStep && (
          <button
            type="submit"
            disabled={!isStepCompleted}
            className={`mt-4 px-4 py-2 rounded ${
              isStepCompleted
                ? "bg-blue-500 text-white hover:bg-blue-600"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          >
            I have completed my assigned actions
          </button>
        )}
      </form>
    </div>
  );
};

export default WorkflowProgress;
