import React, { useEffect, useRef, useState, useCallback } from "react";
import * as atlas from "azure-maps-control";
import "azure-maps-control/dist/atlas.min.css";
import { Asset } from "../../interfaces/asset";
import { Location } from "../../interfaces/location";
import { addPassiveEventListener } from "./passiveEventListenerUtility";

const GlobalMap: React.FC<GlobalMapProps> = ({
  assets,
  locations,
  showAssets,
  showLocations,
  showGeofences,
  onPinHover,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<atlas.Map | null>(null);
  const dataSourceRef = useRef<atlas.source.DataSource | null>(null);
  const geofenceSourceRef = useRef<atlas.source.DataSource | null>(null);
  const [isMapReady, setIsMapReady] = useState(false);

  const initializeMap = useCallback(() => {
    console.log("Initializing map");
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = new atlas.Map(mapRef.current, {
        center: [0, 0],
        zoom: 2,
        language: "en-US",
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY || "",
        },
        style: "road",
        renderWorldCopies: false,
      });

      mapInstance.current.events.add("ready", () => {
        console.log("Map is ready");
        setIsMapReady(true);
      });
    }
  }, []);

  useEffect(() => {
    initializeMap();

    return () => {
      if (mapInstance.current) {
        console.log("Disposing map");
        mapInstance.current.dispose();
        mapInstance.current = null;
      }
      setIsMapReady(false);
    };
  }, [initializeMap]);

  // Setup click handler
  useEffect(() => {
    if (isMapReady && mapInstance.current) {
      console.log("Setting up layers and data sources");
      if (!dataSourceRef.current) {
        dataSourceRef.current = new atlas.source.DataSource(null, {
          cluster: true,
          clusterMaxZoom: 24,
          clusterRadius: 45,
        });
        mapInstance.current.sources.add(dataSourceRef.current);

        console.log("Adding cluster layer");
        mapInstance.current.layers.add(
          new atlas.layer.BubbleLayer(dataSourceRef.current, "clusterLayer", {
            radius: ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
            color: "rgba(0, 120, 255, 0.5)",
            strokeWidth: 0,
            filter: ["has", "point_count"],
          })
        );

        console.log("Adding symbol layer");
        mapInstance.current.layers.add(
          new atlas.layer.SymbolLayer(dataSourceRef.current, "symbolLayer", {
            iconOptions: {
              image: [
                "case",
                ["has", "point_count"],
                "none",
                ["==", ["get", "type"], "asset"],
                "pin-blue",
                ["==", ["get", "type"], "location"],
                "pin-red",
                "pin-blue",
              ],
              allowOverlap: true,
            },
            textOptions: {
              textField: ["get", "name"],
              offset: [0, 1.2],
            },
          })
        );

        console.log("Adding cluster label layer");
        mapInstance.current.layers.add(
          new atlas.layer.SymbolLayer(
            dataSourceRef.current,
            "clusterLabelLayer",
            {
              iconOptions: {
                image: "none",
              },
              textOptions: {
                textField: ["get", "point_count_abbreviated"],
                offset: [0, 0.4],
                color: "#fff",
              },
              filter: ["has", "point_count"],
            }
          )
        );
      }

      if (!geofenceSourceRef.current) {
        console.log("Creating geofence data source and layer");
        geofenceSourceRef.current = new atlas.source.DataSource();
        mapInstance.current.sources.add(geofenceSourceRef.current);

        const geofenceLayer = new atlas.layer.PolygonLayer(
          geofenceSourceRef.current,
          "geofenceLayer",
          {
            fillColor: "rgba(150, 80, 200, 0.3)",
            fillOpacity: 0.5,
            strokeColor: "green",
            strokeWidth: 2,
          }
        );
        mapInstance.current.layers.add(geofenceLayer);
        console.log("Geofence layer added to map");
      }
    }
  }, [isMapReady]);

  console.log("Geofence source data:", geofenceSourceRef.current?.toJson());

  // Update map data
  useEffect(() => {
    console.log("Updating map data");
    if (
      isMapReady &&
      mapInstance.current &&
      dataSourceRef.current &&
      geofenceSourceRef.current
    ) {
      console.log("Clearing existing data");
      dataSourceRef.current.clear();
      geofenceSourceRef.current.clear();

      let pointsAdded = 0;
      let geofencesAdded = 0;

      if (showAssets) {
        console.log("Adding assets to map");
        assets.forEach((asset) => {
          if (asset.coordinates) {
            dataSourceRef.current?.add(
              new atlas.data.Feature(
                new atlas.data.Point([
                  asset.coordinates.longitude,
                  asset.coordinates.latitude,
                ]),
                { type: "asset", name: asset.name, ...asset }
              )
            );
            pointsAdded++;
          }
        });
      }

      if (showLocations) {
        console.log("Adding locations to map");
        locations.forEach((location) => {
          if (location.coordinates) {
            dataSourceRef.current?.add(
              new atlas.data.Feature(
                new atlas.data.Point([
                  location.coordinates.longitude,
                  location.coordinates.latitude,
                ]),
                { type: "location", name: location.name, ...location }
              )
            );
            pointsAdded++;
          }
        });
      }

      if (showGeofences) {
        console.log("Adding geofences to map");
        locations.forEach((location, index) => {
          console.log(`Checking location ${index} for geofence:`, location);
          if (location.geofence && location.geofence.type === "Polygon") {
            console.log(`Adding geofence for location: ${location.name}`);
            console.log("Geofence data:", JSON.stringify(location.geofence));
            geofenceSourceRef.current?.add(
              new atlas.data.Feature(
                new atlas.data.Polygon(location.geofence.coordinates),
                {
                  type: "geofence",
                  locationId: location.id,
                  name: location.name,
                }
              )
            );
            geofencesAdded++;
          } else {
            console.log(`No valid geofence for location: ${location.name}`);
          }
        });
      }

      console.log("showGeofences:", showGeofences);

      console.log(
        `Total points added: ${pointsAdded}, Geofences added: ${geofencesAdded}`
      );

      console.log("Updating geofence layer visibility");
      const geofenceLayer = mapInstance.current.layers.getLayerById(
        "geofenceLayer"
      ) as atlas.layer.PolygonLayer;
      if (geofenceLayer) {
        geofenceLayer.setOptions({ visible: showGeofences });
      }

      // Adjust the map view to fit all points and geofences
      if (pointsAdded > 0 || geofencesAdded > 0) {
        const combinedSource = new atlas.source.DataSource();
        combinedSource.add(dataSourceRef.current.toJson());
        combinedSource.add(geofenceSourceRef.current.toJson());
        mapInstance.current.setCamera({
          bounds: atlas.data.BoundingBox.fromData(combinedSource.toJson()),
          padding: 50,
        });
      }
    }
  }, [isMapReady, assets, locations, showAssets, showLocations, showGeofences]);

  useEffect(() => {
    if (isMapReady && mapInstance.current && dataSourceRef.current) {
      console.log("Setting up click event listener");

      const clickHandler = (e: atlas.MapMouseEvent) => {
        console.log("Map clicked", e);

        // Close any existing popups
        mapInstance.current?.popups.clear();

        console.log("Raw click position:", e.position);

        try {
          const validPosition = validateCoordinates(e.position);
          console.log("Valid position:", validPosition);

          const shapes = e.shapes || [];
          console.log("Shapes at click point:", shapes);

          if (shapes.length > 0) {
            const shape = shapes[0];
            console.log("Clicked shape:", shape);

            // Access properties from the shape's properties or data.properties
            const properties =
              shape.properties || (shape.data && shape.data.properties) || {};
            console.log("Clicked shape properties:", properties);

            if (properties.cluster) {
              console.log("Cluster clicked", properties);
              const clusterId = properties.cluster_id;
              const pointCount = properties.point_count;
              const clusterSource =
                dataSourceRef.current as atlas.source.DataSource;

              clusterSource
                .getClusterLeaves(clusterId, pointCount, 0)
                .then((leaves) => {
                  console.log("Cluster leaves:", leaves);

                  const popupContent = `
                        <div style="padding: 10px; max-width: 300px;">                            
                            <ul style="list-style-type: none; padding: 0; padding-top: 16px; margin: 0;">
                            ${leaves
                              .map((leaf: any) => {
                                const leafProperties =
                                  leaf.data && leaf.data.properties
                                    ? leaf.data.properties
                                    : {};
                                const name = leafProperties.name || "Unnamed";
                                const type = leafProperties.type || "Unknown";
                                const id = leafProperties.id || "";
                                const linkPath =
                                  type === "asset"
                                    ? `/asset/${leaf.data.properties.assetId}`
                                    : `/location/${leaf.data.properties.displayId}`;
                                return `
                                <li style="margin-bottom: 8px;">
                                    <a href="${linkPath}" style="text-decoration: none; color: #0078d4; display: block; padding: 5px; border: 1px solid #ddd; border-radius: 4px;">
                                    ${name} <span style="color: #666; font-size: 0.9em;">(${type})</span>
                                    </a>
                                </li>
                                `;
                              })
                              .join("")}
                            </ul>
                        </div>
                        `;

                  const popup = new atlas.Popup({
                    content: popupContent,
                    position: validPosition,
                    pixelOffset: [0, -30],
                    options: {
                      closeButton: true,
                      fillColor: "white",
                      shadow: true,
                    },
                  });

                  console.log("Popup created:", popup);

                  if (mapInstance.current) {
                    mapInstance.current.popups.add(popup);
                    console.log("Popup added to map");

                    // Center the map on the popup
                    mapInstance.current.setCamera({
                      center: validPosition,
                      zoom: mapInstance.current.getCamera().zoom,
                    });

                    // Force a redraw of the map
                    setTimeout(() => {
                      mapInstance.current?.resize();
                      popup.open(mapInstance.current); // Explicitly open the popup
                    }, 100);
                  } else {
                    console.error(
                      "mapInstance.current is null when trying to add popup"
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error getting cluster leaves:", error);
                });
            } else if (
              properties.type === "asset" ||
              properties.type === "location" ||
              properties.type === "geofence"
            ) {
              // Individual feature logic
              const name = properties.name || "Unnamed";
              const type = properties.type || "Unknown";

              let popupContent = `
                  <div style="padding: 10px; max-width: 300px;">
                    <h3 style="margin-top: 0; margin-bottom: 10px; font-size: 16px; font-weight: bold; padding-top:16px;">${name}</h3>
                    <ul style="list-style-type: none; padding: 0; margin: 0;">
                      <li style="margin-bottom: 8px;">
                        <span style="color: #666; font-size: 0.9em;">Type: ${type}</span>
                      </li>`;

              if (type === "asset") {
                const lastServiceDate = properties.lastServiceDate || "Unknown";
                popupContent += `
                      <li style="margin-bottom: 8px;">
                        <span style="color: #666; font-size: 0.9em;">Last Service Date: ${lastServiceDate}</span>
                      </li>`;
              } else if (type === "geofence") {
                popupContent += `
                      <li style="margin-bottom: 8px;">
                        <span style="color: #666; font-size: 0.9em;">Geofence for: ${
                          properties.name || "Unknown location"
                        }</span>
                      </li>`;
              }

              popupContent += `
                    </ul>
                    <a href="${
                      type === "asset"
                        ? `/asset/${properties.assetId}`
                        : `/location/${properties.displayId}`
                    }" 
                       style="text-decoration: none; color: #0078d4; display: block; padding: 5px; border: 1px solid #ddd; border-radius: 4px; text-align: center; margin-top: 10px;">
                      View Details
                    </a>
                  </div>`;

              const popup = new atlas.Popup({
                content: popupContent,
                position: validPosition,
                pixelOffset: [0, -30],
                options: {
                  closeButton: true,
                  fillColor: "white",
                  shadow: true,
                },
              });

              console.log("Popup created:", popup);

              if (mapInstance.current) {
                mapInstance.current.popups.add(popup);
                console.log("Popup added to map");

                // Center the map on the popup
                mapInstance.current.setCamera({
                  center: validPosition,
                  zoom: mapInstance.current.getCamera().zoom,
                });

                // Force a redraw of the map
                setTimeout(() => {
                  mapInstance.current?.resize();
                  popup.open(mapInstance.current); // Explicitly open the popup
                }, 100);
              } else {
                console.error(
                  "mapInstance.current is null when trying to add popup"
                );
              }
            } else {
              console.log("Unrecognized shape type:", properties);
            }
          } else {
            console.log("No shapes found at click point");
          }
        } catch (error) {
          console.error("Error in click handler:", error);
        }
      };

      // Add the click event to the map
      mapInstance.current.events.add("click", clickHandler);

      return () => {
        console.log("Removing click event listener");
        mapInstance.current?.events.remove("click", clickHandler);
      };
    }
  }, [isMapReady, onPinHover]);

  // Keep the validateCoordinates function as is

  // Keep the validateCoordinates function as is
  const validateCoordinates = (position: number[]): atlas.data.Position => {
    let [longitude, latitude] = position;
    latitude = Math.max(-90, Math.min(90, latitude));
    longitude = ((longitude + 180) % 360) - 180;
    return [longitude, latitude];
  };

  return (
    <div className="relative w-full h-full">
      <div ref={mapRef} style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default GlobalMap;
