import React, { useRef } from "react";
import { FileText, PlusCircle, Upload } from "lucide-react";

interface Document {
  id: number;
  name: string;
  path: string;
}

interface DocumentsGridProps {
  documents: Document[];
  onDocumentUpload: (file: File) => Promise<void>;
  isUploading: boolean;
  sasUrls: { [key: string]: string };
}

const DocumentsGrid: React.FC<DocumentsGridProps> = ({
  documents,
  onDocumentUpload,
  isUploading,
  sasUrls,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddNewDocument = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("File selected for upload:", file.name);
      await onDocumentUpload(file);
    } else {
      console.warn("No file selected");
    }
  };

  const handleDocumentClick = (doc: Document) => {
    const sasUrl = sasUrls[doc.id];
    if (sasUrl) {
      console.log("Opening document with SAS URL:", sasUrl);
      window.open(sasUrl, "_blank");
    } else {
      console.error(`No SAS URL available for document ${doc.id}`);
    }
  };

  return (
    <div className="mb-6">
      <h3 className="text-xl font-semibold mb-2">
        Documents ({documents.length})
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {documents.map((doc) => (
          <div
            key={doc.id}
            className="flex flex-col border border-gray-300 items-center justify-center p-2 rounded-lg aspect-square cursor-pointer hover:bg-gray-200 transition-colors duration-200"
            onClick={() => handleDocumentClick(doc)}
          >
            <FileText size={48} className="mb-2 text-gray-600" />
            <span className="text-xs text-center font-medium truncate w-full">
              {doc.name}
            </span>
          </div>
        ))}
        <div
          className="flex flex-col items-center justify-center p-2 rounded-lg aspect-square cursor-pointer hover:bg-gray-200 transition-colors duration-200 border-2 border-green-500"
          onClick={handleAddNewDocument}
        >
          {isUploading ? (
            <Upload size={24} className="mb-2 text-green-500 animate-pulse" />
          ) : (
            <PlusCircle size={24} className="mb-2 text-green-500" />
          )}
          <span className="text-xs text-center text-green-500 font-semibold hidden sm:block">
            {isUploading ? "Uploading..." : "Add New Document"}
          </span>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
        disabled={isUploading}
      />
    </div>
  );
};

export default DocumentsGrid;
