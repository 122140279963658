import React, { useState, useRef, useEffect } from "react";
import { Camera, Edit, ArrowUpRight, Type, Mic, Search } from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import * as tf from "@tensorflow/tfjs";
import * as mobilenet from "@tensorflow-models/mobilenet";

interface BaseAnnotation {
  type: "arrow" | "text";
}

interface ArrowAnnotation extends BaseAnnotation {
  type: "arrow";
  id: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

interface TextAnnotation extends BaseAnnotation {
  type: "text";
  id: string;
  x: number;
  y: number;
  text: string;
}

interface InProgressArrowAnnotation {
  type: "in-progress-arrow";
  startX: number;
  startY: number;
  endX?: number;
  endY?: number;
}

interface InProgressTextAnnotation {
  type: "in-progress-text";
  x: number;
  y: number;
}

type Annotation = ArrowAnnotation | TextAnnotation;
type InProgressAnnotation =
  | InProgressArrowAnnotation
  | InProgressTextAnnotation;
type CurrentAnnotation = Annotation | InProgressAnnotation | null;

interface Photo {
  id: string;
  src: string;
  features: number[];
  annotations: Annotation[];
}

interface TextInputDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (text: string) => void;
}

function generateUniqueId(): string {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}

function isArrowAnnotation(
  annotation: Annotation
): annotation is ArrowAnnotation {
  return annotation.type === "arrow";
}

function isTextAnnotation(
  annotation: Annotation
): annotation is TextAnnotation {
  return annotation.type === "text";
}

function isInProgressArrowAnnotation(
  annotation: CurrentAnnotation
): annotation is InProgressArrowAnnotation {
  return annotation !== null && annotation.type === "in-progress-arrow";
}

const TextInputDialog: React.FC<TextInputDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [inputText, setInputText] = useState("");
  const [isListening, setIsListening] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(inputText);
    setInputText("");
  };

  const handleSpeechRecognition = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new (window as any).webkitSpeechRecognition();
      recognition.onstart = () => setIsListening(true);
      recognition.onend = () => setIsListening(false);
      recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        setInputText((prevText) => prevText + " " + transcript);
      };
      recognition.start();
    } else {
      alert("Speech recognition is not supported in this browser.");
    }
  };

  return (
    <div
      className={`fixed inset-x-0 bottom-0 bg-white p-4 transition-transform duration-300 ${
        isOpen ? "transform translate-y-0" : "transform translate-y-full"
      }`}
    >
      <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
        <Input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter annotation text"
          className="w-full"
        />
        <div className="flex justify-between">
          <Button
            type="button"
            onClick={handleSpeechRecognition}
            variant="outline"
          >
            <Mic className={`mr-2 ${isListening ? "text-red-500" : ""}`} />
            {isListening ? "Listening..." : "Speak"}
          </Button>
          <div>
            <Button
              type="button"
              onClick={onClose}
              variant="outline"
              className="mr-2"
            >
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const PhotoCaptureAndAnnotation: React.FC = () => {
  const [mode, setMode] = useState<"idle" | "capture" | "annotate" | "recall">(
    "idle"
  );
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [similarPhotoIds, setSimilarPhotoIds] = useState<string[]>([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>(0);
  const [selectedTool, setSelectedTool] = useState<"arrow" | "text" | null>(
    null
  );
  const [isDrawing, setIsDrawing] = useState(false);
  const [isTextDialogOpen, setIsTextDialogOpen] = useState(false);
  const [model, setModel] = useState<mobilenet.MobileNet | null>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [currentAnnotation, setCurrentAnnotation] =
    useState<CurrentAnnotation>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const SIMILARITY_THRESHOLD = 0.99;
  const MAX_SIMILAR_PHOTOS = 10;

  useEffect(() => {
    if (mode === "annotate" && photos.length > 0) {
      drawAnnotations();
    }
  }, [mode, currentPhotoIndex, photos]);

  useEffect(() => {
    const loadModel = async () => {
      try {
        const loadedModel = await mobilenet.load();
        setModel(loadedModel);
        console.log("MobileNet model loaded");
      } catch (error) {
        console.error("Error loading MobileNet model:", error);
      }
    };

    loadModel();
  }, []);

  const addAnnotation = (photoIndex: number, newAnnotation: Annotation) => {
    setPhotos((prevPhotos) =>
      prevPhotos.map((photo, index) =>
        index === photoIndex
          ? { ...photo, annotations: [...photo.annotations, newAnnotation] }
          : photo
      )
    );
  };

  const addTextAnnotation = (x: number, y: number, text: string) => {
    const newAnnotation: TextAnnotation = {
      id: generateUniqueId(),
      type: "text",
      x,
      y,
      text,
    };

    setPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos[currentPhotoIndex].annotations.push(newAnnotation);
      return updatedPhotos;
    });
  };

  const addArrowAnnotation = (
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => {
    const newAnnotation: ArrowAnnotation = {
      id: generateUniqueId(),
      type: "arrow",
      startX,
      startY,
      endX,
      endY,
    };

    setPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos[currentPhotoIndex].annotations.push(newAnnotation);
      return updatedPhotos;
    });
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play();
          setIsCameraReady(true);
        };
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setIsCameraReady(false);
    }
  };

  useEffect(() => {
    if (mode === "capture" || mode === "recall") {
      startCamera();
    } else {
      stopCamera();
    }
    return () => stopCamera();
  }, [mode]);

  const capturePhoto = async () => {
    if (!isCameraReady || !videoRef.current || !canvasRef.current) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas
      .getContext("2d")
      ?.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageDataUrl = canvas.toDataURL("image/jpeg");

    try {
      const features = await extractFeatures(imageDataUrl);
      const newPhoto: Photo = {
        id: generateUniqueId(),
        src: imageDataUrl,
        annotations: [],
        features,
      };

      setPhotos((prevPhotos) => [...prevPhotos, newPhoto]);

      if (mode === "recall") {
        await findSimilarPhotos(features);
      } else {
        setMode("annotate");
      }
    } catch (error) {
      console.error("Error in capturePhoto:", error);
    }
  };

  const extractFeatures = async (imageSrc: string): Promise<number[]> => {
    if (!model) {
      console.error("MobileNet model not loaded");
      return [];
    }
    const img = new Image();
    img.src = imageSrc;
    await new Promise((resolve) => (img.onload = resolve));

    const tfImg = tf.browser.fromPixels(img);
    const normalized = tf.div(tfImg, 255) as tf.Tensor3D;
    const resized = tf.image.resizeBilinear(normalized, [224, 224]);
    const batched = tf.expandDims(resized, 0) as tf.Tensor4D;

    try {
      const result = (await model.infer(batched, true)) as tf.Tensor;
      const features = Array.from(result.dataSync());
      return features;
    } finally {
      tf.dispose([tfImg, normalized, resized, batched]);
    }
  };

  const findSimilarPhotos = async (features: number[]) => {
    const similarities = photos.map((photo) => {
      const similarity = cosineSimilarity(features, photo.features);
      return { photoId: photo.id, similarity };
    });

    const sortedSimilarities = similarities
      .filter((s) => s.similarity > SIMILARITY_THRESHOLD)
      .sort((a, b) => b.similarity - a.similarity)
      .slice(0, MAX_SIMILAR_PHOTOS);

    setSimilarPhotoIds(sortedSimilarities.map((s) => s.photoId));
  };

  const getPhotoById = (id: string): Photo | undefined => {
    return photos.find((photo) => photo.id === id);
  };

  const cosineSimilarity = (a: number[], b: number[]): number => {
    const dotProduct = a.reduce((sum, ai, i) => sum + ai * b[i], 0);
    const magnitudeA = Math.sqrt(a.reduce((sum, ai) => sum + ai * ai, 0));
    const magnitudeB = Math.sqrt(b.reduce((sum, bi) => sum + bi * bi, 0));
    return dotProduct / (magnitudeA * magnitudeB);
  };

  const drawAnnotations = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (canvas && ctx && photos[currentPhotoIndex]) {
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        photos[currentPhotoIndex].annotations.forEach((ann) => {
          if (isArrowAnnotation(ann)) {
            drawArrow(ctx, ann.startX, ann.startY, ann.endX, ann.endY);
          } else if (isTextAnnotation(ann)) {
            drawTextBox(ctx, ann.x, ann.y, ann.x + 100, ann.y + 20, ann.text);
          }
        });
      };
      img.src = photos[currentPhotoIndex].src;
    }
  };

  const drawArrow = (
    ctx: CanvasRenderingContext2D,
    fromX: number,
    fromY: number,
    toX: number,
    toY: number
  ) => {
    ctx.beginPath();
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.strokeStyle = "red";
    ctx.lineWidth = 2;
    ctx.stroke();

    const angle = Math.atan2(toY - fromY, toX - fromX);
    ctx.beginPath();
    ctx.moveTo(toX, toY);
    ctx.lineTo(
      toX - 15 * Math.cos(angle - Math.PI / 6),
      toY - 15 * Math.sin(angle - Math.PI / 6)
    );
    ctx.lineTo(
      toX - 15 * Math.cos(angle + Math.PI / 6),
      toY - 15 * Math.sin(angle + Math.PI / 6)
    );
    ctx.closePath();
    ctx.fillStyle = "red";
    ctx.fill();
  };

  const drawTextBox = (
    ctx: CanvasRenderingContext2D,
    startX: number,
    startY: number,
    endX: number,
    endY: number,
    text?: string
  ) => {
    const width = endX - startX;
    const height = endY - startY;

    ctx.fillStyle = "rgba(255, 255, 255, 0.7)";
    ctx.fillRect(startX, startY, width, height);
    ctx.strokeStyle = "red";
    ctx.strokeRect(startX, startY, width, height);

    if (text) {
      ctx.font = "16px Arial";
      ctx.fillStyle = "black";
      ctx.fillText(text, startX + 5, startY + 20);
    }
  };

  const getClientX = (event: React.MouseEvent | React.TouchEvent): number => {
    if (event.nativeEvent instanceof MouseEvent) {
      return event.nativeEvent.clientX;
    } else {
      return event.nativeEvent.touches[0].clientX;
    }
  };

  const getClientY = (event: React.MouseEvent | React.TouchEvent): number => {
    if (event.nativeEvent instanceof MouseEvent) {
      return event.nativeEvent.clientY;
    } else {
      return event.nativeEvent.touches[0].clientY;
    }
  };

  const handleStart = (event: React.MouseEvent | React.TouchEvent) => {
    if (mode !== "annotate" || !selectedTool) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    event.preventDefault();

    const rect = canvas.getBoundingClientRect();
    const x = getClientX(event) - rect.left;
    const y = getClientY(event) - rect.top;

    setIsDrawing(true);
    if (selectedTool === "arrow") {
      setCurrentAnnotation({
        type: "in-progress-arrow",
        startX: x,
        startY: y,
      });
    } else if (selectedTool === "text") {
      setCurrentAnnotation({
        type: "in-progress-text",
        x: x,
        y: y,
      });
    }
  };

  const handleMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || !currentAnnotation) return;

    const canvas = canvasRef.current;
    if (!canvas) return;

    event.preventDefault();

    const rect = canvas.getBoundingClientRect();
    const x = getClientX(event) - rect.left;
    const y = getClientY(event) - rect.top;

    if (isInProgressArrowAnnotation(currentAnnotation)) {
      setCurrentAnnotation({ ...currentAnnotation, endX: x, endY: y });
      const ctx = canvas.getContext("2d");
      if (ctx) {
        drawArrow(
          ctx,
          currentAnnotation.startX,
          currentAnnotation.startY,
          x,
          y
        );
      }
    } else if (currentAnnotation.type === "in-progress-text") {
      setCurrentAnnotation({ ...currentAnnotation, x, y });
      const ctx = canvas.getContext("2d");
      if (ctx) {
        drawTextBox(
          ctx,
          currentAnnotation.x,
          currentAnnotation.y,
          x + 100,
          y + 20
        );
      }
    }

    drawAnnotations();
  };

  const handleEnd = () => {
    if (!isDrawing || !currentAnnotation) return;

    setIsDrawing(false);
    if (
      isInProgressArrowAnnotation(currentAnnotation) &&
      currentAnnotation.endX !== undefined &&
      currentAnnotation.endY !== undefined
    ) {
      const finalAnnotation: ArrowAnnotation = {
        id: generateUniqueId(),
        type: "arrow",
        startX: currentAnnotation.startX,
        startY: currentAnnotation.startY,
        endX: currentAnnotation.endX,
        endY: currentAnnotation.endY,
      };
      addAnnotation(currentPhotoIndex, finalAnnotation);
      setCurrentAnnotation(null);
    } else if (currentAnnotation.type === "in-progress-text") {
      setIsTextDialogOpen(true);
    }
    drawAnnotations();
  };

  const handleTextSubmit = (text: string) => {
    if (currentAnnotation && currentAnnotation.type === "in-progress-text") {
      const finalAnnotation: TextAnnotation = {
        id: generateUniqueId(),
        type: "text",
        x: currentAnnotation.x,
        y: currentAnnotation.y,
        text,
      };
      addAnnotation(currentPhotoIndex, finalAnnotation);
    }
    setCurrentAnnotation(null);
    setIsTextDialogOpen(false);
    drawAnnotations();
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex justify-center space-x-2">
        <Button
          onClick={() => setMode("capture")}
          disabled={mode === "capture"}
        >
          <Camera className="mr-2" /> Capture
        </Button>
        <Button
          onClick={() => setMode("annotate")}
          disabled={mode === "annotate" || photos.length === 0}
        >
          <Edit className="mr-2" /> Annotate
        </Button>
        <Button onClick={() => setMode("recall")} disabled={mode === "recall"}>
          <Search className="mr-2" /> Recall
        </Button>
      </div>

      {mode === "annotate" && (
        <div className="mb-4 flex justify-center space-x-2">
          <Button
            onClick={() => setSelectedTool("arrow")}
            variant={selectedTool === "arrow" ? "default" : "outline"}
          >
            <ArrowUpRight className="mr-2" /> Arrow
          </Button>
          <Button
            onClick={() => setSelectedTool("text")}
            variant={selectedTool === "text" ? "default" : "outline"}
          >
            <Type className="mr-2" /> Text
          </Button>
        </div>
      )}

      <div className="mb-4">
        {(mode === "capture" || mode === "recall") && (
          <div className={`flex ${mode === "recall" ? "space-x-4" : ""}`}>
            <div className={mode === "recall" ? "w-1/2" : "w-full"}>
              <video
                ref={videoRef}
                className="w-full h-64 object-cover mb-2"
                playsInline
                muted
              />
              <Button
                onClick={capturePhoto}
                className="w-full"
                disabled={!isCameraReady}
              >
                <Camera className="mr-2" /> Capture Photo
              </Button>
              <canvas ref={canvasRef} className="hidden" />
            </div>
            {mode === "recall" && (
              <div className="w-1/2 grid grid-cols-2 gap-2">
                {similarPhotoIds.length > 0 ? (
                  similarPhotoIds.map((photoId) => {
                    const photo = getPhotoById(photoId);
                    return photo ? (
                      <div key={photoId} className="relative">
                        <img
                          src={photo.src}
                          alt={`Similar ${photoId}`}
                          className="w-full h-auto"
                        />
                        {photo.annotations.map((annotation) => {
                          if (annotation.type === "text") {
                            return (
                              <div
                                key={annotation.id}
                                style={{
                                  position: "absolute",
                                  left: `${annotation.x}px`,
                                  top: `${annotation.y}px`,
                                  color: "red",
                                  fontSize: "14px",
                                }}
                              >
                                {annotation.text}
                              </div>
                            );
                          } else if (annotation.type === "arrow") {
                            return (
                              <svg
                                key={annotation.id}
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none",
                                }}
                              >
                                <line
                                  x1={annotation.startX}
                                  y1={annotation.startY}
                                  x2={annotation.endX}
                                  y2={annotation.endY}
                                  stroke="red"
                                  strokeWidth="2"
                                  markerEnd="url(#arrowhead)"
                                />
                              </svg>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ) : null;
                  })
                ) : (
                  <div>No similar photos found</div>
                )}
              </div>
            )}
          </div>
        )}
        {mode === "annotate" && photos.length > 0 && (
          <canvas
            ref={canvasRef}
            className="w-full border border-gray-300"
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onMouseLeave={handleEnd}
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
          />
        )}
        {(mode === "annotate" || mode === "idle") && photos.length === 0 && (
          <div className="w-full h-64 flex items-center justify-center border border-gray-300 bg-gray-100">
            <p className="text-gray-500">No photos captured yet</p>
          </div>
        )}
      </div>

      {photos.length > 0 && mode !== "capture" && mode !== "recall" && (
        <div className="flex justify-between mt-4">
          <Button
            onClick={() =>
              setCurrentPhotoIndex((prevIndex) => Math.max(0, prevIndex - 1))
            }
            disabled={currentPhotoIndex === 0}
          >
            Previous
          </Button>
          <Button
            onClick={() =>
              setCurrentPhotoIndex((prevIndex) =>
                Math.min(photos.length - 1, prevIndex + 1)
              )
            }
            disabled={currentPhotoIndex === photos.length - 1}
          >
            Next
          </Button>
        </div>
      )}

      <TextInputDialog
        isOpen={isTextDialogOpen}
        onClose={() => setIsTextDialogOpen(false)}
        onSubmit={handleTextSubmit}
      />
    </div>
  );
};

export default PhotoCaptureAndAnnotation;
