import React, { useRef, useState, useCallback } from "react";
import { PlusCircle, Play, Loader, FileText, X } from "lucide-react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import VideoPlayer from "./VideoPlayer";
import { useAssetApiClient } from "../../services/api/assetApiClient";

interface Video {
  id: number;
  filename: string;
  description: string;
  locked: boolean;
  transcript?: string;
  summary?: string;
  blobName: string;
  url: string;
  thumbnailBlobName: string;
}

interface VideoGalleryProps {
  videos: Video[];
  assetId: number;
  thumbnailUrls: string[];
  onVideoSelect: (index: number) => void;
  onVideoUpload: (updatedAsset: any) => void; // Changed parameter type
  onRefreshThumbnail: (index: number) => Promise<string>;
}

const VideoGallery: React.FC<VideoGalleryProps> = ({
  videos,
  assetId,
  thumbnailUrls,
  onVideoSelect,
  onVideoUpload,
  onRefreshThumbnail,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const assetApiClient = useAssetApiClient();
  const [refreshingThumbnails, setRefreshingThumbnails] = useState<{
    [key: number]: boolean;
  }>({});

  const handleAddVideoClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      // Validate file type and size
      if (!file.type.startsWith("video/")) {
        setErrors((prev) => [...prev, "Please select a valid video file"]);
        return;
      }

      if (file.size > 100 * 1024 * 1024) {
        // 100MB limit
        setErrors((prev) => [...prev, "File size must be less than 100MB"]);
        return;
      }

      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append("video", file);

        // Upload using the API client and get the updated asset
        const updatedAsset = await assetApiClient.uploadVideo(
          assetId.toString(),
          formData
        );

        // Call the parent's onVideoUpload callback with the updated asset
        onVideoUpload(updatedAsset);
      } catch (error) {
        console.error("Error uploading video:", error);
        setErrors((prev) => [...prev, `Failed to upload ${file.name}`]);
      } finally {
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  const handleVideoClick = async (video: Video, index: number) => {
    try {
      const videoUrl = await assetApiClient.getFileSasToken(
        assetId.toString(),
        video.blobName
      );
      setSelectedVideo({ ...video, url: videoUrl });
      onVideoSelect(index);
    } catch (error) {
      console.error(`Error fetching video URL for ${video.filename}:`, error);
      setErrors((prev) => [...prev, `Failed to load video ${video.filename}`]);
    }
  };

  const handleThumbnailError = useCallback(
    async (index: number) => {
      setRefreshingThumbnails((prev) => ({ ...prev, [index]: true }));
      try {
        const newUrl = await onRefreshThumbnail(index);
        thumbnailUrls[index] = newUrl;
        setRefreshingThumbnails((prev) => ({ ...prev, [index]: false }));
      } catch (error) {
        console.error("Error refreshing thumbnail:", error);
        setRefreshingThumbnails((prev) => ({ ...prev, [index]: false }));
      }
    },
    [onRefreshThumbnail, thumbnailUrls]
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-2">Videos ({videos.length})</h3>
      {errors.length > 0 && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          <strong className="font-bold">Errors:</strong>
          <ul className="list-disc list-inside">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
          <Button
            onClick={() => setErrors([])}
            variant="ghost"
            size="sm"
            className="mt-2"
          >
            <X className="h-4 w-4 mr-1" />
            Clear Errors
          </Button>
        </div>
      )}
      <div className="grid grid-cols-3 gap-2">
        {videos.map((video, index) => (
          <div
            key={video.id}
            className="rounded-lg overflow-hidden border border-gray-300 aspect-video relative cursor-pointer"
            onClick={() => handleVideoClick(video, index)}
          >
            {thumbnailUrls[index] ? (
              <img
                src={thumbnailUrls[index]}
                alt={`Thumbnail for ${video.filename}`}
                className="w-full h-full object-cover"
                onError={() => handleThumbnailError(index)}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                <FileText size={48} className="text-gray-400" />
              </div>
            )}
            <div className="absolute inset-0 flex items-center justify-center">
              <Play size={48} className="text-white opacity-80" />
            </div>
            <div className="absolute bottom-2 left-2 right-2 bg-black bg-opacity-50 text-white p-1 text-sm truncate">
              {video.filename}
            </div>
            {refreshingThumbnails[index] && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <span className="text-white">Refreshing...</span>
              </div>
            )}
          </div>
        ))}
        <div className="flex flex-col items-center justify-center p-2 rounded-lg aspect-video cursor-pointer hover:bg-gray-200 border-2 border-green-500">
          <Button
            onClick={handleAddVideoClick}
            variant="ghost"
            className="w-full h-full flex flex-col items-center justify-center"
            disabled={isUploading}
          >
            {isUploading ? (
              <Loader size={24} className="mb-1 animate-spin" />
            ) : (
              <PlusCircle size={24} className="mb-1 text-green-500" />
            )}
            <span className="text-xs text-center text-green-500 font-semibold hidden sm:block">
              {isUploading ? "Uploading..." : "Add New Video"}
            </span>
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            className="hidden"
            capture="environment"
          />
        </div>
      </div>
      {selectedVideo && (
        <Dialog
          open={!!selectedVideo}
          onOpenChange={() => setSelectedVideo(null)}
        >
          <DialogContent className="max-w-4xl w-full max-h-[90vh] p-6 bg-white flex flex-col">
            <DialogHeader>
              <DialogTitle className="flex justify-between items-center">
                <span>{selectedVideo.filename}</span>
                <Button
                  onClick={() => setSelectedVideo(null)}
                  variant="ghost"
                  className="p-1"
                />
              </DialogTitle>
            </DialogHeader>
            <div className="flex-grow overflow-auto">
              <div className="mt-4">
                <VideoPlayer src={selectedVideo.url} />
              </div>
              {(selectedVideo.transcript || selectedVideo.summary) && (
                <div className="mt-4 space-y-4">
                  {selectedVideo.transcript && (
                    <div>
                      <h4 className="font-semibold">Transcript</h4>
                      <p className="text-sm mt-2">{selectedVideo.transcript}</p>
                    </div>
                  )}
                  {selectedVideo.summary && (
                    <div>
                      <h4 className="font-semibold">Summary</h4>
                      <p className="text-sm mt-2">{selectedVideo.summary}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default VideoGallery;
