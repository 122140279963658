// dashboardData.ts
export const mockDashboardData = {
  name: "Test Equipment",
  locationId: 1,
  location: "Depot",
  type: "asset",
  assetType: "Equipment",
  group: "equipment",
  lastServiceDate: "2024-10-25",
  lastServiceBy: "System",
  documents: [
    {
      name: "Service-Maint-App.pdf",
      contentType: "application/pdf",
      size: 798262,
      id: 1729861544913,
    },
  ],
  images: [
    {
      id: 1729861550185,
      filename: "hero.jpg",
      description: "Uploaded image",
      locked: false,
    },
  ],
  videos: [
    {
      id: 1729861732915,
      filename: "Fieldbase-poc-Sept11_short.mp4",
      description: "Uploaded video",
      locked: false,
    },
  ],
  maintenanceNotes: [
    {
      id: 1729861744431,
      date: "2024-10-25",
      technician: "Joey Martin",
      note: "Test note",
    },
    {
      id: 1729861744432,
      date: "2024-10-24",
      technician: "Sarah Smith",
      note: "Routine inspection completed",
    },
    {
      id: 1729861744433,
      date: "2024-10-23",
      technician: "Mike Johnson",
      note: "Replaced worn components",
    },
  ],
  solutions: [
    {
      id: 1729861759710,
      problem: "Irregular noise during operation",
      solution: "Replaced bearing assembly",
      status: "Pending approval",
    },
    {
      id: 1729861759711,
      problem: "Oil leak detected",
      solution: "Sealed gasket replacement",
      status: "Completed",
    },
    {
      id: 1729861759715,
      problem: "Oil leak detected",
      solution: "Sealed gasket replacement",
      status: "Completed",
    },
  ],
  checklistItems: [
    {
      id: 1729861572137,
      name: "Safety check",
      questions: ["Item 1", "Another item"],
    },
    {
      id: 1729861572138,
      name: "Operation check",
      questions: ["Check 1", "Check 2"],
    },
    {
      id: 1729861572139,
      name: "Operation check",
      questions: ["Check 1", "Check 2"],
    },
    {
      id: 1729861572148,
      name: "Operation check",
      questions: ["Check 1", "Check 2"],
    },
  ],
  completedChecklists: [
    {
      id: 1729861717477,
      checklistId: 1729861572137,
      name: "Safety check",
      completedBy: "Joey Martin",
      completedAt: "2024-10-25T13:08:37.477Z",
      answers: [true, true],
    },
  ],
  coordinates: {
    latitude: 44.603454,
    longitude: -63.5962694,
  },
};
