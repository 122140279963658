import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { PlusCircle } from "lucide-react";
import { Button } from "../ui/button";
import SpeechToText from "./SpeechToText";

interface MaintenanceNote {
  id: number;
  date: string;
  technician: string;
  note: string;
}

interface MaintenanceNotesSectionProps {
  showMaintenanceNotes: boolean;
  maintenanceNotes: MaintenanceNote[];
  showAddMaintenanceNote: boolean;
  onAddMaintenanceNote: () => void;
  onSubmitMaintenanceNote: (noteText: string) => void;
  onCancelAddMaintenanceNote: () => void;
}

const MaintenanceNotesSection: React.FC<MaintenanceNotesSectionProps> = ({
  showMaintenanceNotes,
  maintenanceNotes,
  showAddMaintenanceNote,
  onAddMaintenanceNote,
  onSubmitMaintenanceNote,
  onCancelAddMaintenanceNote,
}) => {
  const [newNote, setNewNote] = useState("");

  const handleSave = () => {
    if (newNote.trim()) {
      onSubmitMaintenanceNote(newNote);
      setNewNote("");
    }
  };

  const handleCancel = () => {
    setNewNote("");
    onCancelAddMaintenanceNote();
  };

  const handleTranscript = (transcript: string) => {
    setNewNote(transcript);
  };

  // Sort the maintenance notes by date in descending order
  const sortedNotes = [...maintenanceNotes].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <AnimatePresence>
      {showMaintenanceNotes && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.1 }}
          className="bg-white p-4 rounded-lg border border-gray-300 mb-4"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Maintenance Notes</h3>
            <Button
              onClick={onAddMaintenanceNote}
              className="bg-green-500 hover:bg-green-600 text-white"
            >
              <PlusCircle className="mr-2" size={16} />
              <span className="text-xs text-center text-white font-semibold hidden sm:block">
                Add New
              </span>
            </Button>
          </div>
          {showAddMaintenanceNote && (
            <div className="mb-4">
              <SpeechToText
                onTranscript={handleTranscript}
                placeholder="Enter new maintenance note"
                textareaClass="w-full pr-10 mb-2 resize-none border border-gray-200 p-2"
                buttonClass="text-gray-400 hover:text-gray-600"
                initialText={newNote}
              />
              <div className="flex justify-end space-x-2">
                <Button onClick={handleCancel} variant="outline">
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  className="bg-blue-500 hover:bg-blue-600 text-white"
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          <ul className="space-y-2">
            {sortedNotes.map((note: MaintenanceNote) => (
              <li key={note.id} className="border-b border-gray-200 pb-2">
                <p className="font-semibold">
                  {note.date} - {note.technician}
                </p>
                <p>{note.note}</p>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MaintenanceNotesSection;
