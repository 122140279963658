import React, { useState, useEffect } from "react";
import { useAssetApiClient } from "../../services/api/assetApiClient";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Location } from "../../interfaces/location";

interface AddAssetDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAssetAdded: () => void;
  defaultLocationId?: number;
}

export default function AddAssetDialog({
  isOpen,
  onClose,
  onAssetAdded,
  defaultLocationId,
}: AddAssetDialogProps) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [locationType, setLocationType] = useState<string>("");
  const [assetType, setAssetType] = useState<string>("");
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>(
    defaultLocationId?.toString() || ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const assetApiClient = useAssetApiClient();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const fetchedLocations = await assetApiClient.getAllLocations();
        setLocations(fetchedLocations);

        if (defaultLocationId) {
          setSelectedLocation(defaultLocationId.toString());
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        setError("Failed to fetch locations");
      }
    };

    if (isOpen) {
      fetchLocations();
    }
  }, [assetApiClient, defaultLocationId, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    if (!name.trim() || !selectedLocation || !assetType) {
      setError("Please fill in all required fields");
      setIsSubmitting(false);
      return;
    }

    try {
      const selectedLocationObj = locations.find(
        (loc) => loc.id.toString() === selectedLocation
      );

      if (!selectedLocationObj) {
        throw new Error("Selected location not found");
      }

      // Create new asset with required fields
      const newAsset = {
        name: name.trim(),
        locationId: parseInt(selectedLocation),
        location: selectedLocationObj.name,
        type: "asset", // This is important - it identifies the item as an asset
        assetType: assetType, // This stores Equipment/Tool/Vehicle
        group: assetType.toLowerCase(),
        lastServiceDate: new Date().toISOString().split("T")[0],
        lastServiceBy: "System",
        documents: [],
        images: [],
        videos: [],
        maintenanceNotes: [],
        solutions: [],
        checklistItems: [],
      };

      // Create the asset
      const createdAsset = await assetApiClient.createAsset(newAsset);

      // Call the callback to refresh any asset lists
      onAssetAdded();

      // Close the dialog
      handleClose();

      // Navigate to the new asset's page
      navigate(`/asset/${createdAsset.assetId}`);
    } catch (error) {
      console.error("Error creating asset:", error);
      setError("Failed to create asset. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setName("");
    setAssetType("");
    setError(null);
    if (!defaultLocationId) {
      setSelectedLocation("");
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Add New Asset
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4 mt-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">
              Asset Name
            </label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter asset name"
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="location" className="text-sm font-medium">
              Location
            </label>
            <Select
              value={selectedLocation}
              onValueChange={setSelectedLocation}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select location" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectGroup>
                  <SelectLabel>Locations</SelectLabel>
                  {locations.map((location) => (
                    <SelectItem
                      key={location.id}
                      value={location.id.toString()}
                    >
                      {location.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <label htmlFor="type" className="text-sm font-medium">
              Asset Type
            </label>
            <Select value={assetType} onValueChange={setAssetType}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select asset type" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectGroup>
                  <SelectLabel>Types</SelectLabel>
                  <SelectItem value="Equipment">Equipment</SelectItem>
                  <SelectItem value="Tool">Tool</SelectItem>
                  <SelectItem value="Vehicle">Vehicle</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          {error && <div className="text-red-500 text-sm">{error}</div>}

          <DialogFooter className="flex justify-end space-x-2 mt-6">
            <Button
              type="button"
              variant="outline"
              onClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              {isSubmitting ? "Creating..." : "Create Asset"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
