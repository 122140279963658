import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Updated mock NFC tag data
const nfcTagData = [
  { nfcTagId: 1, type: "location", name: "SW pumping station" },
  { nfcTagId: 2, type: "location", name: "Control room" },
  { nfcTagId: 3, type: "asset", name: "Midi controller APC-mini" },
  { nfcTagId: 4, type: "asset", name: "Audio interface" },
  { nfcTagId: 5, type: "asset", name: "Audio interface" },
  { nfcTagId: 6, type: "asset", name: "Audio interface" },
  { nfcTagId: 7, type: "asset", name: "Audio interface" },
  {
    nfcTagId: 8,
    type: "automation",
    name: "SW centrifugal pump emergency shut-down",
  },
  {
    nfcTagId: 9,
    type: "automation",
    name: "SW centrifugal pump -- maintenance update",
  },
];

const NFCTagHandler: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nfcTagId = searchParams.get("nfcTagId");

    if (nfcTagId) {
      const tagData = nfcTagData.find(
        (tag) => tag.nfcTagId === parseInt(nfcTagId)
      );
      if (tagData) {
        switch (tagData.type) {
          case "location":
            navigate(`/location/${nfcTagId}`);
            break;
          case "asset":
            navigate(`/asset/${nfcTagId}`);
            break;
          case "automation":
            navigate(`/automation/${nfcTagId}`);
            break;
          default:
            console.error("Unknown tag type");
            navigate("/");
        }
      } else {
        console.error("Invalid NFC tag ID");
        navigate("/");
      }
    }
  }, [location, navigate]);

  return null;
};

export default NFCTagHandler;
