import axios, { AxiosInstance } from "axios";
import { Asset } from "../interfaces/asset";
import { useAuth } from "@clerk/clerk-react";
import { Location, LocationType } from "../interfaces/location";
import { useMemo } from "react";

const API_BASE_URL =
  window.REACT_APP_API_BASE_URL ||
  process.env.REACT_APP_API_BASE_URL ||
  "http://localhost:5000/api";

interface SasTokenCache {
  [key: string]: {
    url: string;
    expiresAt: number;
  };
}

const sasTokenCache: SasTokenCache = {};

const createAxiosInstance = (
  getToken: () => Promise<string | null>
): AxiosInstance => {
  const instance = axios.create({
    baseURL: API_BASE_URL,
  });

  instance.interceptors.request.use(async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newToken = await getToken();
        if (newToken) {
          axios.defaults.headers.common["Authorization"] = "Bearer " + newToken;
          return instance(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

interface ChatMessage {
  role: "user" | "assistant";
  content: string;
}

export const useAssetApiClient = () => {
  const { getToken } = useAuth();

  return useMemo(() => {
    const axiosInstance = createAxiosInstance(() => getToken());

    return {
      async getAllAssets(): Promise<Asset[]> {
        try {
          console.log(`Fetching assets from: ${API_BASE_URL}/assets`);
          const response = await axiosInstance.get(`/assets`, {
            headers: {
              Accept: "application/json",
            },
          });
          console.log("API Response:", response);
          console.log("Response headers:", response.headers);
          console.log("Response data:", response.data);

          if (Array.isArray(response.data)) {
            return response.data.filter((item) => item.type === "asset");
          } else {
            console.error("Unexpected API response format:", response.data);
            return [];
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error("Axios error:", error.message);
            console.error("Response data:", error.response?.data);
            console.error("Response status:", error.response?.status);
            console.error("Response headers:", error.response?.headers);
          } else {
            console.error("Error fetching all assets:", error);
          }
          throw error;
        }
      },

      async createAsset(assetData: Partial<Asset>): Promise<Asset> {
        try {
          const response = await axiosInstance.post("/assets", assetData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error creating asset:", error);
          if (axios.isAxiosError(error) && error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          }
          throw error;
        }
      },

      async updateAsset(
        assetId: number,
        updateData: Partial<Asset>
      ): Promise<Asset | undefined> {
        try {
          console.log("Sending update request:", { assetId, updateData });
          const response = await axiosInstance.patch(
            `/assets/${assetId}`,
            updateData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error updating asset:", error);
          if (axios.isAxiosError(error) && error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          }
          throw error;
        }
      },

      async uploadFile(assetId: string, formData: FormData): Promise<Asset> {
        try {
          console.log(`Uploading file for assetId: ${assetId}`);
          console.log("FormData contents:", Array.from(formData.entries()));

          const response = await axiosInstance.post(
            `/assets/${assetId}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("File upload response:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error uploading file:", error);
          if (axios.isAxiosError(error) && error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          }
          throw error;
        }
      },

      async uploadVideo(assetId: string, formData: FormData): Promise<Asset> {
        try {
          console.log(`Uploading video for assetId: ${assetId}`);
          console.log("FormData contents:", Array.from(formData.entries()));

          const response = await axiosInstance.post(
            `/assets/${assetId}/upload-video`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("Video upload response:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error uploading video:", error);
          if (axios.isAxiosError(error) && error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          }
          throw error;
        }
      },

      async uploadImage(assetId: string, formData: FormData): Promise<Asset> {
        try {
          console.log(`Uploading image for assetId: ${assetId}`);
          console.log("FormData contents:", Array.from(formData.entries()));

          const response = await axiosInstance.post(
            `/assets/${assetId}/upload-image`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("Image upload response:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error uploading image:", error);
          if (axios.isAxiosError(error) && error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          }
          throw error;
        }
      },

      async getFileSasToken(
        assetId: string,
        blobName: string
      ): Promise<string> {
        try {
          const response = await axiosInstance.get(`/assets/${assetId}/file`, {
            params: { blobName },
          });

          return response.data.url;
        } catch (error) {
          console.error("Error fetching SAS token:", error);
          throw error;
        }
      },

      async refreshSasToken(
        assetId: string,
        blobName: string
      ): Promise<string> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/refresh-sas`,
            { blobName }
          );
          return response.data.url;
        } catch (error) {
          console.error("Error refreshing SAS token:", error);
          throw error;
        }
      },

      async searchAssetContext(assetId: number, query: string): Promise<any> {
        try {
          const response = await axiosInstance.post(`/search`, {
            assetId,
            query,
          });
          return response.data;
        } catch (error) {
          console.error("Error searching asset context:", error);
          throw error;
        }
      },

      async addMaintenanceNote(
        assetId: string,
        note: { date: string; technician: string; note: string }
      ): Promise<Asset> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/maintenance-notes`,
            note,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error adding maintenance note:", error);
          throw error;
        }
      },

      async addSolutionToAsset(
        assetId: string,
        solution: { problem: string; solution: string; status: string }
      ): Promise<Asset> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/solutions`,
            solution,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error adding solution to asset:", error);
          throw error;
        }
      },

      // assetApiClient.ts
      async getAIAssistantResponse(
        chatHistory: ChatMessage[],
        assetId: number
      ): Promise<string> {
        try {
          const response = await axiosInstance.post(`/ai-assistant`, {
            chatHistory,
            assetId,
          });
          return response.data.response;
        } catch (error) {
          console.error("Error getting AI assistant response:", error);
          throw error;
        }
      },

      async triggerDocumentIndexing(
        assetId: string,
        blobName: string
      ): Promise<void> {
        const response = await axiosInstance.post(`/index-document`, {
          assetId,
          blobName,
        });

        if (response.status !== 200) {
          throw new Error("Failed to trigger document indexing");
        }
      },

      async getAllLocations(): Promise<Location[]> {
        try {
          const response = await axiosInstance.get(`/locations`);
          return response.data;
        } catch (error) {
          console.error("Error fetching all locations:", error);
          return [];
        }
      },

      async getLocationById(displayId: number): Promise<{
        location: Location;
        subLocations: Location[];
        assets: Asset[];
      }> {
        try {
          const response = await axiosInstance.get(`/locations/${displayId}`);
          return response.data;
        } catch (error) {
          console.error("Error fetching location:", error);
          throw error;
        }
      },

      async getLocation(locationId: string | number): Promise<{
        location: Location;
        subLocations: Location[];
        assets: Asset[];
      }> {
        try {
          console.log(
            `Fetching location details for locationId: ${locationId}`
          );
          const response = await axiosInstance.get(`/locations/${locationId}`);
          console.log("Location API response:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching location:", error);
          throw error;
        }
      },

      async getAssetsByLocationId(locationId: number): Promise<Asset[]> {
        try {
          const response = await axiosInstance.get(`/assets`, {
            params: { locationId },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching assets by location:", error);
          return [];
        }
      },

      async getAssetById(assetId: number): Promise<Asset | undefined> {
        try {
          const response = await axiosInstance.get(`/assets/${assetId}`);
          return response.data;
        } catch (error) {
          console.error("Error fetching asset by ID:", error);
          throw error;
        }
      },

      async getLocationContents(locationId: number): Promise<{
        location: Location;
        subLocations: Location[];
        assets: Asset[];
      }> {
        try {
          console.log(
            `Fetching location contents for locationId: ${locationId}`
          );
          const response = await axiosInstance.get(
            `/locations/${locationId}/contents`
          );
          console.log("API response:", response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching location contents:", error);
          throw error;
        }
      },

      async updateLocation(
        locationId: number | string,
        updateData: Partial<Location>
      ): Promise<Location> {
        try {
          console.log("Updating location:", { locationId, updateData });
          const response = await axiosInstance.patch(
            `/locations/${locationId}`,
            updateData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error updating location:", error);
          throw error;
        }
      },

      async addChecklistToAsset(
        assetId: number,
        checklist: { id: number; name: string; questions: string[] }
      ): Promise<Asset> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/checklists`,
            checklist,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error adding checklist to asset:", error);
          throw error;
        }
      },

      async addCompletedChecklistToAsset(
        assetId: number,
        completedChecklist: CompletedChecklist
      ): Promise<Asset> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/completed-checklists`,
            completedChecklist,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error adding completed checklist to asset:", error);
          throw error;
        }
      },

      async rateSolution(
        assetId: string,
        solutionId: number,
        rating: "up" | "down",
        comment: string
      ): Promise<Asset> {
        try {
          const response = await axiosInstance.post(
            `/assets/${assetId}/solutions/${solutionId}/rate`,
            { rating, comment },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error rating solution:", error);
          throw error;
        }
      },

      async updateImage(
        assetId: string,
        imageIndex: number,
        formData: FormData
      ): Promise<Asset> {
        try {
          // Note: Remove the "this." before axiosInstance
          const response = await axiosInstance.put(
            `/assets/${assetId}/images/${imageIndex}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error("Error updating image:", error);
          throw error;
        }
      },
    };
  }, [getToken]);
};
