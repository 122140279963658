import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Stage,
  Layer,
  Text,
  Arrow,
  Rect,
  Image as KonvaImage,
  Transformer,
} from "react-konva";
import { Button } from "../ui/button";
import {
  Type,
  ArrowUpRight,
  Square,
  Pointer,
  Edit,
  Settings,
  Trash2,
} from "lucide-react";
import AnnotationSubMenu from "./AnnotationSubMenu";
import FloatingTextInput from "./FloatingTextInput";

interface Annotation {
  id: string;
  type: "text" | "arrow" | "rectangle";
  x: number;
  y: number;
  width?: number;
  height?: number;
  text?: string;
  points?: number[];
  color: string;
  opacity: number;
  hasBackground?: boolean;
}

interface ImageAnnotatorProps {
  imageSrc: string;
  onSave: (imageBlob: Blob) => void;
  onCancel: () => void;
  onImageLoadError?: () => void; // Add this
}

const ImageAnnotator: React.FC<ImageAnnotatorProps> = ({
  imageSrc,
  onSave,
  onCancel,
  onImageLoadError,
}) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [selectedTool, setSelectedTool] = useState<
    "select" | "text" | "arrow" | "rectangle" | "edit" | null
  >("select");
  const [selectedId, selectAnnotation] = useState<string | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState("#FF0000");
  const [opacity, setOpacity] = useState(1);
  const [hasBackground, setHasBackground] = useState(false);
  const [editingText, setEditingText] = useState<string | null>(null);

  const stageRef = useRef<Konva.Stage>(null);
  const layerRef = useRef<Konva.Layer>(null);
  const transformerRef = useRef<Konva.Transformer>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    const img = new window.Image();
    img.crossOrigin = "anonymous";

    const handleLoad = () => {
      setImage(img);
      updateStageSizeToFitImage(img);
      setError(null);
    };

    const handleError = (e: ErrorEvent) => {
      console.error("Error loading image:", e);
      setImage(null);
      setError(new Error("Failed to load image"));
      if (onImageLoadError) {
        onImageLoadError();
      }
    };

    img.addEventListener("load", handleLoad);
    img.addEventListener("error", handleError);
    img.src = imageSrc;

    return () => {
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [imageSrc, onImageLoadError]);

  const updateStageSizeToFitImage = (img: HTMLImageElement) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const padding = 40; // Add some padding

      const imageAspectRatio = img.width / img.height;
      const containerAspectRatio = containerWidth / containerHeight;

      let newWidth, newHeight;

      if (imageAspectRatio > containerAspectRatio) {
        // Image is wider than container
        newWidth = containerWidth - padding;
        newHeight = (containerWidth - padding) / imageAspectRatio;
      } else {
        // Image is taller than container
        newHeight = containerHeight - padding;
        newWidth = (containerHeight - padding) * imageAspectRatio;
      }

      setStageSize({
        width: newWidth,
        height: newHeight,
      });
    }
  };

  const handleTransform = (id: string, newProps: any) => {
    setAnnotations((prevAnnotations) =>
      prevAnnotations.map((ann) =>
        ann.id === id
          ? {
              ...ann,
              ...newProps,
              // Update fontSize based on vertical scaling
              fontSize: ann.fontSize
                ? Math.round(
                    ann.fontSize * Math.abs(newProps.scaleY || ann.scaleY || 1)
                  )
                : 20,
              // Reset scale after applying it to fontSize
              scaleX: 1,
              scaleY: 1,
            }
          : ann
      )
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (image) {
        updateStageSizeToFitImage(image);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [image]);

  useEffect(() => {
    if (selectedId && transformerRef.current && layerRef.current) {
      const node = layerRef.current.findOne(`#${selectedId}`);
      if (node) {
        transformerRef.current.nodes([node]);
        // Add specific transformer settings for text
        if (node instanceof window.Konva.Text) {
          transformerRef.current.setAttrs({
            enabledAnchors: [
              "middle-left",
              "middle-right",
              "top-center",
              "bottom-center", // Add vertical anchors
            ],
            // Allow both horizontal and vertical scaling
            boundBoxFunc: (oldBox, newBox) => {
              // Minimum size constraints
              newBox.width = Math.max(30, newBox.width);
              newBox.height = Math.max(20, newBox.height); // Minimum height
              return newBox;
            },
          });
        } else {
          // Reset to default settings for other shapes
          transformerRef.current.setAttrs({
            enabledAnchors: [
              "top-left",
              "top-right",
              "bottom-left",
              "bottom-right",
              "middle-left",
              "middle-right",
              "top-center",
              "bottom-center",
            ],
            boundBoxFunc: (oldBox, newBox) => {
              newBox.width = Math.max(5, newBox.width);
              newBox.height = Math.max(5, newBox.height);
              return newBox;
            },
          });
        }
        transformerRef.current.getLayer().batchDraw();
      }
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      setAnnotations((prevAnnotations) =>
        prevAnnotations.map((ann) =>
          ann.id === selectedId
            ? { ...ann, color, opacity, hasBackground }
            : ann
        )
      );
    }
  }, [selectedId, color, opacity, hasBackground]);

  useEffect(() => {
    if (selectedTool !== "edit" && selectedTool !== "text") {
      setEditingText(null);
      selectAnnotation(null);
    }
  }, [selectedTool]);

  useEffect(() => {
    if (selectedId && (selectedTool === "edit" || selectedTool === "text")) {
      const selectedAnnotation = annotations.find(
        (ann) => ann.id === selectedId
      );
      if (selectedAnnotation && selectedAnnotation.type === "text") {
        setEditingText(selectedAnnotation.text || "");
      } else {
        setEditingText(null);
      }
    }
  }, [selectedId, annotations, selectedTool]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedId, annotations, editingText]);

  const deleteSelectedAnnotation = useCallback(() => {
    if (selectedId) {
      setAnnotations(annotations.filter((ann) => ann.id !== selectedId));
      // Clear transformer nodes
      if (transformerRef.current) {
        transformerRef.current.nodes([]);
        transformerRef.current.getLayer()?.batchDraw();
      }
      selectAnnotation(null);
    }
  }, [selectedId, annotations]);

  // Update the handlers to use the new function
  const handleDelete = () => {
    deleteSelectedAnnotation();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    // Don't handle delete if we're editing text
    if (editingText !== null) {
      return;
    }

    if ((e.key === "Delete" || e.key === "Backspace") && selectedId) {
      deleteSelectedAnnotation();
    }
  };

  const handleMouseDown = (e: any) => {
    if (selectedTool === "select" || selectedTool === "edit") {
      const clickedOnEmpty = e.target === e.target.getStage();
      if (clickedOnEmpty) {
        selectAnnotation(null);
      } else {
        const clickedAnnotationId = e.target.id();
        selectAnnotation(clickedAnnotationId);
      }
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    setIsDrawing(true);

    const newAnnotation: Annotation = {
      id: Date.now().toString(),
      type: selectedTool,
      x: pos.x,
      y: pos.y,
      color,
      opacity,
      hasBackground: selectedTool === "text" ? hasBackground : undefined,
      fontSize: selectedTool === "text" ? 20 : undefined,
      scaleX: 1,
      scaleY: 1,
    };

    if (selectedTool === "text") {
      newAnnotation.text = "New text";
      setEditingText("New text");
      selectAnnotation(newAnnotation.id);
    } else if (selectedTool === "arrow") {
      newAnnotation.points = [0, 0, 0, 0];
    } else if (selectedTool === "rectangle") {
      newAnnotation.width = 0;
      newAnnotation.height = 0;
    }

    setAnnotations([...annotations, newAnnotation]);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing) return;

    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const lastAnnotation = annotations[annotations.length - 1];

    if (lastAnnotation.type === "arrow") {
      lastAnnotation.points = [
        0,
        0,
        point.x - lastAnnotation.x,
        point.y - lastAnnotation.y,
      ];
    } else if (lastAnnotation.type === "rectangle") {
      lastAnnotation.width = point.x - lastAnnotation.x;
      lastAnnotation.height = point.y - lastAnnotation.y;
    }

    annotations.splice(annotations.length - 1, 1, lastAnnotation);
    setAnnotations([...annotations]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleTextEdit = (id: string) => {
    if (selectedTool !== "edit" && selectedTool !== "text") return;

    const annotation = annotations.find((a) => a.id === id);
    if (!annotation) return;

    setEditingText(annotation.text || "");
    selectAnnotation(id);
  };

  const handleTextSave = (newText: string) => {
    if (selectedId) {
      setAnnotations((prevAnnotations) =>
        prevAnnotations.map((ann) =>
          ann.id === selectedId ? { ...ann, text: newText } : ann
        )
      );
      setEditingText(null);
      if (selectedTool === "text") {
        setSelectedTool("select");
      }
    }
  };

  const handleTextCancel = () => {
    setEditingText(null);
    selectAnnotation(null);
    if (selectedTool === "text") {
      setSelectedTool("select");
    }
  };

  const saveAnnotatedImage = async () => {
    const stage = stageRef.current;
    if (!stage || !image) {
      console.error("Stage or image is not ready", {
        stageReady: !!stage,
        imageReady: !!image,
      });
      return;
    }

    try {
      // Create a new canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Could not get canvas context");
      }

      // Set canvas size to match stage size with device pixel ratio
      const pixelRatio = window.devicePixelRatio || 1;
      canvas.width = Math.floor(stage.width() * pixelRatio);
      canvas.height = Math.floor(stage.height() * pixelRatio);

      // Scale context for retina/high DPI displays
      ctx.scale(pixelRatio, pixelRatio);

      // First draw the background
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, stage.width(), stage.height());

      // Draw the base image
      ctx.drawImage(image, 0, 0, stage.width(), stage.height());

      // Draw stage content onto the canvas
      const stageCanvas = stage.toCanvas({
        pixelRatio: pixelRatio,
        width: stage.width(),
        height: stage.height(),
      });

      // Composite the stage canvas over our main canvas
      ctx.drawImage(stageCanvas, 0, 0);

      // Convert to blob
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to create image blob"));
            }
          },
          "image/jpeg",
          0.95
        );
      });

      onSave(blob);
    } catch (error) {
      console.error("Error in saveAnnotatedImage:", error);
      if (error instanceof Error) {
        setError(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="mb-4 flex items-center space-x-2 self-start">
        <div className="bg-gray-100 rounded-full p-1 shadow-md">
          <div className="flex space-x-1">
            <Button
              onClick={() => setSelectedTool("select")}
              variant={selectedTool === "select" ? "default" : "ghost"}
              size="icon"
              className={`rounded-full ${
                selectedTool === "select"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
            >
              <Pointer size={20} />
            </Button>
            <Button
              onClick={() => setSelectedTool("text")}
              variant={selectedTool === "text" ? "default" : "ghost"}
              size="icon"
              className={`rounded-full ${
                selectedTool === "text"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
            >
              <Type size={20} />
            </Button>
            <Button
              onClick={() => setSelectedTool("arrow")}
              variant={selectedTool === "arrow" ? "default" : "ghost"}
              size="icon"
              className={`rounded-full ${
                selectedTool === "arrow"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
            >
              <ArrowUpRight size={20} />
            </Button>
            <Button
              onClick={() => setSelectedTool("rectangle")}
              variant={selectedTool === "rectangle" ? "default" : "ghost"}
              size="icon"
              className={`rounded-full ${
                selectedTool === "rectangle"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
            >
              <Square size={20} />
            </Button>
            <Button
              onClick={() => setSelectedTool("edit")}
              variant={selectedTool === "edit" ? "default" : "ghost"}
              size="icon"
              className={`rounded-full ${
                selectedTool === "edit"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
            >
              <Edit size={20} />
            </Button>

            {selectedId && (
              <Button
                onClick={handleDelete}
                variant="ghost"
                size="icon"
                className="rounded-full text-red-500 hover:bg-red-100"
              >
                <Trash2 size={20} />
              </Button>
            )}
          </div>
        </div>
        <Button
          onClick={toggleSubMenu}
          variant="ghost"
          size="icon"
          className="rounded-full bg-gray-100 shadow-md"
        >
          <Settings size={20} className="text-gray-700" />
        </Button>
        {isSubMenuOpen && (
          <AnnotationSubMenu
            tool={selectedTool}
            color={color}
            opacity={opacity}
            hasBackground={hasBackground}
            onColorChange={setColor}
            onOpacityChange={setOpacity}
            onBackgroundToggle={() => setHasBackground(!hasBackground)}
          />
        )}
      </div>
      <div
        ref={containerRef}
        className="relative flex-grow w-full h-[calc(90vh-200px)] overflow-hidden"
      >
        {image && (
          <Stage
            ref={stageRef}
            width={stageSize.width}
            height={stageSize.height}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            pixelRatio={window.devicePixelRatio || 1}
            tabIndex={1}
          >
            <Layer>
              <KonvaImage
                image={image}
                width={stageSize.width}
                height={stageSize.height}
              />
            </Layer>
            <Layer ref={layerRef}>
              {annotations.map((annotation) => {
                if (annotation.type === "text") {
                  return (
                    <Text
                      key={annotation.id}
                      id={annotation.id}
                      x={annotation.x}
                      y={annotation.y}
                      text={annotation.text}
                      fontSize={annotation.fontSize || 20}
                      fill={annotation.color}
                      opacity={annotation.opacity}
                      draggable
                      onClick={() => handleTextEdit(annotation.id)}
                      onTap={() => handleTextEdit(annotation.id)}
                      onTransform={(e) => {
                        const node = e.target;
                        handleTransform(annotation.id, {
                          x: node.x(),
                          y: node.y(),
                          scaleX: node.scaleX(),
                          scaleY: node.scaleY(),
                          rotation: node.rotation(),
                        });
                      }}
                      onTransformEnd={(e) => {
                        const node = e.target;
                        handleTransform(annotation.id, {
                          x: node.x(),
                          y: node.y(),
                          scaleX: node.scaleX(),
                          scaleY: node.scaleY(),
                          rotation: node.rotation(),
                        });
                      }}
                      {...(annotation.hasBackground
                        ? {
                            fillAfterStrokeEnabled: true,
                            stroke: "white",
                            strokeWidth: 5,
                          }
                        : {})}
                    />
                  );
                } else if (annotation.type === "arrow") {
                  return (
                    <Arrow
                      key={annotation.id}
                      id={annotation.id}
                      points={annotation.points}
                      stroke={annotation.color}
                      fill={annotation.color}
                      opacity={annotation.opacity}
                      x={annotation.x}
                      y={annotation.y}
                      draggable
                      onClick={() => selectAnnotation(annotation.id)}
                      onTap={() => selectAnnotation(annotation.id)}
                    />
                  );
                } else if (annotation.type === "rectangle") {
                  return (
                    <Rect
                      key={annotation.id}
                      id={annotation.id}
                      x={annotation.x}
                      y={annotation.y}
                      width={annotation.width}
                      height={annotation.height}
                      stroke={annotation.color}
                      opacity={annotation.opacity}
                      strokeWidth={2}
                      draggable
                      onClick={() => selectAnnotation(annotation.id)}
                      onTap={() => selectAnnotation(annotation.id)}
                    />
                  );
                }
                return null;
              })}
              <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                  }
                  newBox.width = Math.max(30, newBox.width);
                  return newBox;
                }}
              />
            </Layer>
          </Stage>
        )}

        {!image && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="text-gray-500">
              {error ? "Error loading image" : "Loading image..."}
            </div>
          </div>
        )}
      </div>
      {editingText !== null &&
        (selectedTool === "edit" || selectedTool === "text") && (
          <FloatingTextInput
            initialText={editingText}
            onSave={handleTextSave}
            onCancel={handleTextCancel}
          />
        )}
      <div className="mt-4 space-x-2">
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white"
          onClick={saveAnnotatedImage}
        >
          Save
        </Button>
        <Button onClick={onCancel} variant="outline">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ImageAnnotator;
