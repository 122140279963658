import React, { useState, useEffect, useRef } from "react";
import { Button } from "../ui/button";
import { Mic, Send } from "lucide-react";

interface FloatingTextInputProps {
  initialText: string;
  onSave: (text: string) => void;
  onCancel: () => void;
}

const FloatingTextInput: React.FC<FloatingTextInputProps> = ({
  initialText,
  onSave,
  onCancel,
}) => {
  const [text, setText] = useState(initialText);
  const [isListening, setIsListening] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleVoiceInput = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new (window as any).webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event: any) => {
        const transcript = event.results[0][0].transcript;
        setText((prevText) => prevText + " " + transcript);
      };

      recognition.onerror = (event: any) => {
        console.error("Speech recognition error", event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.start();
    } else {
      alert("Speech recognition is not supported in this browser.");
    }
  };

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-96 bg-white rounded-lg shadow-lg p-4">
      <textarea
        ref={textareaRef}
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="w-full h-24 p-2 border rounded mb-2 resize-none"
        placeholder="Enter your text here..."
      />
      <div className="flex justify-between">
        <Button onClick={handleVoiceInput} disabled={isListening}>
          <Mic className={isListening ? "text-red-500" : ""} />
          {isListening ? "Listening..." : "Voice Input"}
        </Button>
        <div>
          <Button onClick={() => onSave(text)} className="mr-2">
            <Send className="mr-2" />
            Save
          </Button>
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FloatingTextInput;
