const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/api";

export const apiService = {
  async setupProfile(profileData: any) {
    try {
      const response = await fetch(`${API_BASE_URL}/setup-profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profileData),
        credentials: "include",
      });

      if (!response.ok) {
        const textResponse = await response.text();
        console.error("Server response:", textResponse);
        throw new Error(`Server responded with status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error("Error in setupProfile:", error);
      throw error;
    }
  },

  async setupOrganization(orgData: any) {
    const response = await fetch(`${API_BASE_URL}/setup-organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orgData),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to setup organization");
    }

    return response.json();
  },

  // Add other API calls here as needed
};
