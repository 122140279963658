import React, { useState } from "react";
import { X, ChevronLeft, ChevronRight, RefreshCcw } from "lucide-react";
import { useSwipeable } from "react-swipeable";

interface FullSizeImageViewProps {
  images: string[];
  selectedIndex: number | null;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onRefreshImage: (index: number) => Promise<string>;
}

const FullSizeImageView: React.FC<FullSizeImageViewProps> = ({
  images,
  selectedIndex,
  onClose,
  onNext,
  onPrevious,
  onRefreshImage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onPrevious,
    trackMouse: true,
  });

  if (selectedIndex === null || images.length === 0) return null;

  const currentImage = images[selectedIndex];

  if (!currentImage) return null;

  const handleImageError = async () => {
    setError("Failed to load image. Attempting to refresh...");
    setIsLoading(true);
    try {
      const newUrl = await onRefreshImage(selectedIndex);
      images[selectedIndex] = newUrl;
      setError(null);
    } catch (err) {
      setError("Failed to refresh image. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative max-w-4xl max-h-full flex flex-col items-center">
        <button
          onClick={onClose}
          className="absolute top-[-60px] right-0 p-2 bg-white rounded-full shadow-lg hover:bg-gray-200"
          aria-label="Close full-size view"
        >
          <X size={24} />
        </button>
        <div className="relative" {...handlers}>
          {selectedIndex > 0 && (
            <button
              onClick={onPrevious}
              className="absolute left-[-60px] top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-200"
              aria-label="Previous image"
            >
              <ChevronLeft size={36} />
            </button>
          )}
          <img
            src={currentImage}
            alt={`Full size asset photo ${selectedIndex + 1}`}
            className="max-w-full max-h-[80vh] object-contain"
          />
          {selectedIndex < images.length - 1 && (
            <button
              onClick={onNext}
              className="absolute right-[-60px] top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-200"
              aria-label="Next image"
            >
              <ChevronRight size={36} />
            </button>
          )}
        </div>
      </div>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 px-4 py-2 rounded-full text-white">
        {selectedIndex + 1} / {images.length}
      </div>
    </div>
  );
};

export default FullSizeImageView;
