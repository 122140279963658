import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ImageAnnotator from "../common/ImageAnnotator";
import assetData from "../../data/assets/assets.json";

const ImageAnnotationPage: React.FC = () => {
  const { assetId, imageId } = useParams<{
    assetId: string;
    imageId: string;
  }>();
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const asset = assetData.find((a) => a.assetId === parseInt(assetId || ""));
    const image = asset?.images?.find(
      (img) => img.id === parseInt(imageId || "")
    );

    if (image) {
      setImageSrc(`/images/${image.filename}`);
    } else {
      // Handle case where image is not found
      console.error("Image not found");
      navigate(`/asset/${assetId}`);
    }
  }, [assetId, imageId, navigate]);

  const handleSave = (annotations: any[]) => {
    // Here you would typically send the annotations to your backend
    console.log("Saving annotations:", annotations);
    // For now, we'll just navigate back to the asset page
    navigate(`/asset/${assetId}`);
  };

  const handleCancel = () => {
    navigate(`/asset/${assetId}`);
  };

  if (!imageSrc) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Image Annotation</h1>
      <ImageAnnotator
        imageSrc={imageSrc}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default ImageAnnotationPage;
