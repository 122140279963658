import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import ChatBar from "../../common/ChatBar";
import CheckInOutComponent from "../../common/CheckInOutComponent";
import { useAssetApiClient } from "../../../services/api/assetApiClient";
import { Location as LocationType } from "../../../interfaces/location";
import { Asset } from "../../../interfaces/asset";
import { Button } from "../../ui/button";
import { MapPin } from "lucide-react";
import AzureMap from "../../common/AzureMap";
import AddAssetDialog from "../../common/AddAssetDialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../ui/dialog";
import atlas from "azure-maps-control";

const toTitleCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const Location: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [location, setLocation] = useState<LocationType | null>(null);
  const [subLocations, setSubLocations] = useState<LocationType[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCoordinatesDialog, setShowCoordinatesDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [tempCoordinates, setTempCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddAssetDialogOpen, setIsAddAssetDialogOpen] = useState(false);
  const [geofence, setGeofence] = useState<atlas.data.Polygon | null>(null);
  const [isEditingGeofence, setIsEditingGeofence] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const assetApiClient = useAssetApiClient();

  useEffect(() => {
    const fetchLocationDetails = async () => {
      if (!id) return;

      setLoading(true);
      try {
        console.log(`Fetching location with ID: ${id}`);
        const locationData = await assetApiClient.getLocation(id);
        console.log("Location data received:", locationData);

        if (locationData && locationData.location) {
          setLocation(locationData.location);
          setGeofence(locationData.location.geofence || null);
          setSubLocations(locationData.subLocations || []);
          setAssets(locationData.assets || []);
        } else {
          throw new Error("Location data is incomplete or missing");
        }
      } catch (err) {
        console.error("Error fetching location:", err);
        setError(
          err instanceof Error
            ? err.message
            : "Failed to fetch location details"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLocationDetails();
  }, [id, assetApiClient]);

  const refreshAssets = useCallback(async () => {
    if (!id) return;
    try {
      const locationData = await assetApiClient.getLocation(id);
      if (locationData && locationData.location) {
        setAssets(locationData.assets || []);
      }
    } catch (err) {
      console.error("Error refreshing assets:", err);
    }
  }, [id, assetApiClient]);

  const handleMapPinClick = async () => {
    setIsLoading(true);
    if (!location?.coordinates) {
      try {
        const position = await getCurrentPosition();
        const newCoordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setTempCoordinates(newCoordinates);
        setShowConfirmationDialog(true);
      } catch (error) {
        console.error("Error getting location:", error);
        setError("Failed to get current location. Please try again.");
      }
    } else {
      setShowCoordinatesDialog(true);
    }
    setIsLoading(false);
  };

  const handleGeofenceChange = useCallback(
    async (newGeofence: atlas.data.Polygon | null) => {
      if (!location) return;

      console.log("Updating geofence for location:", location.id);

      try {
        const serializedGeofence = newGeofence
          ? {
              type: "Polygon" as const,
              coordinates: newGeofence.coordinates,
            }
          : null;

        const updatedLocation = await assetApiClient.updateLocation(
          location.id,
          { geofence: serializedGeofence }
        );

        setLocation(updatedLocation);
        setGeofence(newGeofence);
        setSaveError(null);
      } catch (error) {
        console.error("Error saving geofence:", error);
        setSaveError("Failed to save geofence");
      }
    },
    [location, assetApiClient]
  );

  const handleConfirmCoordinates = async () => {
    if (!tempCoordinates || !location) return;

    try {
      setIsLoading(true);
      const updatedLocation = await assetApiClient.updateLocation(location.id, {
        coordinates: tempCoordinates,
      });

      setLocation(updatedLocation);
      setShowConfirmationDialog(false);
      setShowCoordinatesDialog(true);
    } catch (error) {
      console.error("Error updating coordinates:", error);
      setError("Failed to update coordinates");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditGeofence = () => {
    setIsEditingGeofence(!isEditingGeofence);
    setSaveError(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-lg">Loading location details...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  if (!location) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-lg">Location not found</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 pb-20">
      <div className="flex items-center mb-4">
        <h1 className="text-3xl font-bold mr-2">{location.name}</h1>
        <Button
          onClick={handleMapPinClick}
          variant="ghost"
          size="lg"
          className="p-1"
          disabled={isLoading}
        >
          <MapPin
            className={`w-7 h-7 ${
              location.coordinates ? "text-blue-500" : "text-gray-500"
            }`}
          />
          <span className="sr-only">Location</span>
        </Button>
      </div>

      {location.description && (
        <p className="text-lg text-gray-600 mb-4">{location.description}</p>
      )}

      <CheckInOutComponent name={location.name} type="location" />

      {subLocations.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold mb-4">Sub-locations</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {subLocations.map((subLocation) => (
              <Link
                to={`/location/${subLocation.displayId}`}
                key={subLocation.id}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 p-4"
              >
                <h3 className="text-lg font-semibold">{subLocation.name}</h3>
                {subLocation.description && (
                  <p className="text-sm text-gray-600">
                    {subLocation.description}
                  </p>
                )}
              </Link>
            ))}
          </div>
        </div>
      )}

      {assets.length > 0 ? (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Assets at this location</h2>
            <Button
              onClick={() => setIsAddAssetDialogOpen(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Add Asset
            </Button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {assets.map((asset) => (
              <Link
                to={`/asset/${asset.assetId}`}
                key={asset.assetId}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
              >
                <div className="p-4">
                  <h3 className="text-lg font-semibold">{asset.name}</h3>
                  <p className="text-sm text-gray-600">
                    {toTitleCase(asset.group)}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-lg text-gray-600 mt-8">
          No assets found at this location.
        </p>
      )}

      {/* Dialogs */}
      <Dialog
        open={showCoordinatesDialog}
        onOpenChange={setShowCoordinatesDialog}
      >
        <DialogContent className="bg-white dark:bg-gray-800 rounded-lg shadow-lg">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              Location Map
            </DialogTitle>
          </DialogHeader>
          {location.coordinates ? (
            <div className="mt-4">
              <div className="h-88 w-full">
                <AzureMap
                  latitude={location.coordinates.latitude}
                  longitude={location.coordinates.longitude}
                  pinColor="red"
                  title={location.name}
                  geofence={geofence}
                  isEditable={isEditingGeofence}
                  onGeofenceChange={handleGeofenceChange}
                />
              </div>
              <div className="mt-4 flex justify-between">
                <Button onClick={handleEditGeofence} variant="outline">
                  {isEditingGeofence ? "Finish Editing" : "Edit Geofence"}
                </Button>
              </div>
              {saveError && (
                <div className="mt-2 text-red-500">{saveError}</div>
              )}
            </div>
          ) : (
            <p>No coordinates available for this location.</p>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={showConfirmationDialog}
        onOpenChange={setShowConfirmationDialog}
      >
        <DialogContent className="bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full">
          <DialogHeader>
            <DialogTitle className="text-lg font-semibold">
              Confirm Location Coordinates
            </DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Do you want to set the location's coordinates to your current
            position?
          </DialogDescription>
          <DialogFooter>
            <Button
              onClick={() => setShowConfirmationDialog(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmCoordinates} disabled={isLoading}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* <ChatBar
        title="Field Assistant"
        placeholder="Tell me about this location"
        locationId={location.id}
      /> */}

      <AddAssetDialog
        isOpen={isAddAssetDialogOpen}
        onClose={() => setIsAddAssetDialogOpen(false)}
        onAssetAdded={refreshAssets}
        defaultLocationId={location?.id ? parseInt(location.id) : undefined}
      />
    </div>
  );
};

export default Location;
