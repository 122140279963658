import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Checkbox } from "../../ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import availableActionsData from "../../../data/shared/availableActions.json";
import rolesData from "../../../data/shared/roles.json";
import usersData from "../../../data/shared/users.json";
import workflowsData from "../../../data/workflows/workflows.json";
import workflowTriggersData from "../../../data/workflows/workflowTriggers.json";

interface Task {
  id: number;
  name: string;
}

interface Step {
  id: number;
  assignee: string | null;
  tasks: number[];
}

interface Workflow {
  id: number;
  name: string;
  description: string;
  trigger: string;
  steps: Step[];
}

const defaultWorkflow: Workflow = {
  id: 0,
  name: "",
  description: "",
  trigger: "",
  steps: [],
};

const WorkflowComponent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState<Workflow>(defaultWorkflow);
  const [availableTasks, setAvailableTasks] = useState<Task[]>([]);
  const [assignees, setAssignees] = useState<string[]>([]);
  const [jsonView, setJsonView] = useState<string>("");
  const [mode, setMode] = useState<"add" | "edit">("add");

  useEffect(() => {
    setAvailableTasks(availableActionsData);
    const roles = rolesData.map((role) => `Role: ${role.name}`);
    const persons = usersData.map((user) => `Person: ${user.username}`);
    setAssignees([...roles, ...persons]);

    if (id) {
      const workflowId = parseInt(id);
      const existingWorkflow = workflowsData.find((w) => w.id === workflowId);
      if (existingWorkflow) {
        setWorkflow(existingWorkflow);
        setMode("edit");
      } else {
        console.error(`Workflow with id ${id} not found`);
        navigate("/admin");
      }
    } else {
      setMode("add");
      setWorkflow({ ...defaultWorkflow, id: generateNewId() });
    }
  }, [id, navigate]);

  const generateNewId = (): number => {
    return Math.max(...workflowsData.map((w) => w.id), 0) + 1;
  };

  const updateWorkflow = (field: keyof Workflow, value: string) => {
    setWorkflow({ ...workflow, [field]: value });
  };

  const addStep = () => {
    setWorkflow({
      ...workflow,
      steps: [...workflow.steps, { id: Date.now(), assignee: null, tasks: [] }],
    });
  };

  const updateStep = (
    index: number,
    field: keyof Step,
    value: Step[keyof Step]
  ) => {
    const newSteps = [...workflow.steps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setWorkflow({ ...workflow, steps: newSteps });
  };

  const toggleTask = (stepIndex: number, taskId: number) => {
    const newSteps = [...workflow.steps];
    const taskIndex = newSteps[stepIndex].tasks.indexOf(taskId);
    if (taskIndex > -1) {
      newSteps[stepIndex].tasks.splice(taskIndex, 1);
    } else {
      newSteps[stepIndex].tasks.push(taskId);
    }
    setWorkflow({ ...workflow, steps: newSteps });
  };

  const isOptionDisabled = (option: string) => {
    return workflow.steps.some((step) => step.assignee === option);
  };

  const isTaskDisabled = (taskId: number) => {
    return workflow.steps.some((step) => step.tasks.includes(taskId));
  };

  const updateJsonView = () => {
    setJsonView(JSON.stringify(workflow, null, 2));
  };

  const saveWorkflow = () => {
    console.log("Saving workflow:", workflow);
    alert(`Workflow ${mode === "add" ? "added" : "updated"} successfully!`);
    navigate("/admin");
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        {mode === "add" ? "Add" : "Edit"} Workflow
      </h1>

      <div className="mb-4">
        <Label htmlFor="workflowName">Workflow Name</Label>
        <Input
          id="workflowName"
          value={workflow.name}
          onChange={(e) => updateWorkflow("name", e.target.value)}
          className="mb-2 bg-white"
        />

        <Label htmlFor="workflowDescription">Workflow Description</Label>
        <Input
          id="workflowDescription"
          value={workflow.description}
          onChange={(e) => updateWorkflow("description", e.target.value)}
          className="mb-2 bg-white"
        />

        <Label htmlFor="workflowTrigger">Workflow Trigger</Label>
        <Select
          value={workflow.trigger || undefined}
          onValueChange={(value) => updateWorkflow("trigger", value)}
        >
          <SelectTrigger className="w-full bg-white">
            <SelectValue placeholder="Select a trigger" />
          </SelectTrigger>
          <SelectContent
            className="bg-white z-50 shadow-lg max-h-60 overflow-y-auto"
            position="popper"
            sideOffset={5}
          >
            {workflowTriggersData.map((trigger) => (
              <SelectItem
                key={trigger.id}
                value={trigger.id}
                className="py-2 px-4 hover:bg-gray-100 pl-8"
              >
                {trigger.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {workflow.steps.map((step, index) => (
        <div
          key={step.id}
          className="mb-6 p-4 border rounded relative bg-slate-100"
        >
          <h2 className="text-xl font-semibold mb-2">Step {index + 1}</h2>

          <div className="mb-4">
            <Label>Assign to:</Label>
            <Select
              value={step.assignee || undefined}
              onValueChange={(value) => updateStep(index, "assignee", value)}
            >
              <SelectTrigger className="w-full bg-white">
                <SelectValue placeholder="Select role or person" />
              </SelectTrigger>
              <SelectContent
                className="bg-white z-50 shadow-lg max-h-60 overflow-y-auto"
                position="popper"
                sideOffset={5}
              >
                {assignees.map((assignee) => (
                  <SelectItem
                    key={assignee}
                    value={assignee}
                    disabled={isOptionDisabled(assignee)}
                    className="py-2 px-4 hover:bg-gray-100 pl-8"
                  >
                    {assignee}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <Label>Tasks:</Label>
            {availableTasks.map((task) => (
              <div key={task.id} className="flex items-center mt-2">
                <Checkbox
                  id={`task-${step.id}-${task.id}`}
                  checked={step.tasks.includes(task.id)}
                  onCheckedChange={() => toggleTask(index, task.id)}
                  disabled={
                    isTaskDisabled(task.id) && !step.tasks.includes(task.id)
                  }
                />
                <label
                  htmlFor={`task-${step.id}-${task.id}`}
                  className="ml-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {task.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}

      <Button onClick={addStep} className="mb-4">
        Add Step
      </Button>

      <div className="mt-6">
        <Button onClick={saveWorkflow}>
          {mode === "add" ? "Create" : "Save Changes"}
        </Button>
      </div>

      {jsonView && (
        <div className="mt-6">
          <h2 className="text-xl font-semibold mb-2">Current Workflow JSON</h2>
          <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
            {jsonView}
          </pre>
        </div>
      )}
    </div>
  );
};

export default WorkflowComponent;
