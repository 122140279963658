import React, { useRef, useState, useCallback, useEffect } from "react";
import { PlusCircle, Edit, Lock, RefreshCw } from "lucide-react";
import { Button } from "../ui/button";
import { useAssetApiClient } from "../../services/api/assetApiClient";

interface Image {
  id: number;
  filename: string;
  description: string;
  locked: boolean;
  blobName: string;
  url?: string;
}

interface PhotoGalleryProps {
  images: Image[];
  assetId: string;
  onPhotoSelect: (index: number) => void;
  onAnnotateImage: (index: number) => void;
  onRefreshTokens: () => Promise<void>;
  onPhotoUpload: (updatedAsset: any) => void;
  isUploading: boolean;
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  images,
  assetId,
  onPhotoSelect,
  onAnnotateImage,
  onRefreshTokens,
  onPhotoUpload,
  isUploading: externalIsUploading,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const assetApiClient = useAssetApiClient();
  const [refreshingUrls, setRefreshingUrls] = useState<{
    [key: number]: boolean;
  }>({});
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>(
    {}
  );
  const [retryCount, setRetryCount] = useState<{ [key: number]: number }>({});
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isLocalUploading, setIsLocalUploading] = useState(false);

  const isUploading = externalIsUploading || isLocalUploading;

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      setUploadError("No file selected");
      return;
    }

    if (!file.type.startsWith("image/")) {
      setUploadError("Please select a valid image file");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      setUploadError("File size must be less than 10MB");
      return;
    }

    try {
      setIsLocalUploading(true);
      setUploadError(null);

      const formData = new FormData();
      formData.append("image", file);

      // Upload and get the updated asset directly
      const updatedAsset = await assetApiClient.uploadImage(assetId, formData);

      // Call the callback with the updated asset
      onPhotoUpload(updatedAsset);

      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error uploading photo:", error);
      setUploadError(
        error instanceof Error ? error.message : "Failed to upload photo"
      );
    } finally {
      setIsLocalUploading(false);
    }
  };

  const handleAddPhotoClick = () => {
    fileInputRef.current?.click();
  };

  const handleImageError = useCallback(
    async (index: number) => {
      console.log(
        `Image error for index ${index}. Current URL: ${images[index]?.url}`
      );

      if (retryCount[index] >= 3) {
        console.log(`Max retries reached for index ${index}`);
        setErrorMessages((prev) => ({
          ...prev,
          [index]: "Failed to load image after multiple attempts.",
        }));
        return;
      }

      setRefreshingUrls((prev) => ({ ...prev, [index]: true }));
      setErrorMessages((prev) => ({
        ...prev,
        [index]: "Refreshing image URL...",
      }));

      try {
        await onRefreshTokens();
        setRefreshingUrls((prev) => ({ ...prev, [index]: false }));
        setErrorMessages((prev) => ({ ...prev, [index]: "" }));
        setRetryCount((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }));
      } catch (error) {
        console.error(`Error refreshing SAS token for index ${index}:`, error);
        setRefreshingUrls((prev) => ({ ...prev, [index]: false }));
        setErrorMessages((prev) => ({
          ...prev,
          [index]: "Failed to refresh image. Retrying...",
        }));
        setRetryCount((prev) => ({ ...prev, [index]: (prev[index] || 0) + 1 }));
      }
    },
    [onRefreshTokens, images, retryCount]
  );

  const renderPhoto = (photo: Image, index: number) => {
    if (!photo) return null;

    return (
      <div
        key={photo.id}
        className="rounded-lg overflow-hidden border border-gray-300 aspect-square relative"
      >
        <div className="w-full h-full flex items-center justify-center p-1">
          {photo.url ? (
            <img
              src={photo.url}
              alt={photo.description}
              className="max-w-full max-h-full object-contain cursor-pointer"
              onClick={() => onPhotoSelect(index)}
              onError={() => handleImageError(index)}
            />
          ) : (
            <div className="flex flex-col items-center justify-center">
              <RefreshCw size={24} className="mb-2 text-gray-400" />
              <span className="text-sm text-gray-500">Loading image...</span>
            </div>
          )}
          {refreshingUrls[index] && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <RefreshCw size={24} className="text-white animate-spin" />
            </div>
          )}
          {errorMessages[index] && (
            <div className="absolute bottom-0 left-0 right-0 bg-red-500 text-white text-xs p-1">
              {errorMessages[index]}
            </div>
          )}
        </div>
        <Button
          variant="ghost"
          size="sm"
          className="absolute top-2 right-2 bg-white rounded-full p-1"
          onClick={() => (photo.locked ? null : onAnnotateImage(index))}
        >
          {photo.locked ? (
            <Lock size={16} className="text-gray-500" />
          ) : (
            <Edit size={16} className="text-blue-500" />
          )}
        </Button>
      </div>
    );
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-2">Photos ({images.length})</h3>
      {uploadError && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {uploadError}
        </div>
      )}
      <div className="grid grid-cols-3 gap-2">
        {images.map((photo, index) => renderPhoto(photo, index))}
        <div className="flex flex-col items-center justify-center p-2 rounded-lg aspect-square cursor-pointer hover:bg-gray-200 border-2 border-green-500">
          <Button
            onClick={handleAddPhotoClick}
            variant="ghost"
            className="w-full h-full flex flex-col items-center justify-center"
            disabled={isUploading}
          >
            <PlusCircle size={24} className="mb-1 text-green-500" />
            <span className="text-xs text-center text-green-500 font-semibold hidden sm:block">
              {isUploading ? "Uploading..." : "Add New Photo"}
            </span>
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            capture="environment"
          />
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
