import React from "react";
import { useNavigate } from "react-router-dom";
import workflowsData from "../../data/workflows/workflows.json";

interface Workflow {
  id: number;
  name: string;
  description: string;
}

const Admin: React.FC = () => {
  const navigate = useNavigate();

  const handleAddWorkflow = () => {
    navigate("/admin/add-workflow");
  };

  const handleEditWorkflow = (id: number) => {
    navigate(`/admin/edit-workflow/${id}`);
  };

  const handleManageRoles = () => {
    // This is a mock function for now
    console.log("Manage Roles button clicked");
  };

  const handleManageUsers = () => {
    // This is a mock function for now
    console.log("Manage Users button clicked");
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">User Management</h2>
        <div className="flex space-x-4">
          <button
            onClick={handleManageRoles}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Manage Roles
          </button>
          <button
            onClick={handleManageUsers}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Manage Users
          </button>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Workflow Management</h2>
        <button
          onClick={handleAddWorkflow}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-4"
        >
          Add New Workflow
        </button>
        <div className="space-y-2">
          {workflowsData.map((workflow: Workflow) => (
            <div
              key={workflow.id}
              className="flex items-center justify-between bg-white p-4 rounded-lg shadow"
            >
              <div>
                <h3 className="font-semibold">{workflow.name}</h3>
                <p className="text-sm text-gray-600">{workflow.description}</p>
              </div>
              <button
                onClick={() => handleEditWorkflow(workflow.id)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Edit
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Admin;
