import React from "react";
import { CheckSquare, PlusCircle } from "lucide-react";

interface ChecklistItem {
  id: number;
  name: string;
  questions: string[];
}

interface ChecklistsGridProps {
  checklistItems: ChecklistItem[];
  onChecklistSelect: (checklist: ChecklistItem) => void;
  onAddNewChecklist: () => void;
}

const ChecklistsGrid: React.FC<ChecklistsGridProps> = ({
  checklistItems,
  onChecklistSelect,
  onAddNewChecklist,
}) => {
  return (
    <div className="mb-6">
      <h3 className="text-xl font-semibold mb-2">
        Checklists ({checklistItems.length})
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {checklistItems.map((item) => (
          <div
            key={item.id}
            className="border border-gray-300 flex flex-col items-center justify-center p-2 rounded-lg aspect-square cursor-pointer hover:bg-gray-300"
            onClick={() => onChecklistSelect(item)}
          >
            <CheckSquare size={24} className="mb-1" />
            <span className="text-xs text-center">{item.name}</span>
          </div>
        ))}
        <div
          className="flex flex-col items-center justify-center p-2 rounded-lg aspect-square cursor-pointer hover:bg-gray-200 border-2 border-green-500"
          onClick={onAddNewChecklist}
        >
          <PlusCircle size={24} className="mb-1 text-green-500" />
          <span className="text-xs text-center text-green-500 font-semibold hidden sm:block">
            Add New Checklist
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChecklistsGrid;
