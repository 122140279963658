import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Info } from "lucide-react";

interface AssetInfoSectionProps {
  showInfo: boolean;
  location: string;
  lastServiceDate: string;
  lastServiceBy: string;
  group: string;
}

const AssetInfoSection: React.FC<AssetInfoSectionProps> = ({
  showInfo,
  location,
  lastServiceDate,
  lastServiceBy,
  group,
}) => {
  return (
    <AnimatePresence>
      {showInfo && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.1 }}
          className="bg-white p-4 rounded-lg border border-gray-300 mb-4"
        >
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold text-gray-600">Location</p>
              <p>{location || "Not specified"}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Asset Type</p>
              <p className="capitalize">{group || "Not specified"}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Last Service Date</p>
              <p>{lastServiceDate || "No service record"}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Last Serviced By</p>
              <p>{lastServiceBy || "Not specified"}</p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AssetInfoSection;
