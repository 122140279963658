import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import workflowTemplates from "../../../data/workflows/workflows.json";
import workflowProgressData from "../../../data/workflows/workflowProgress.json";

interface WorkflowTemplate {
  id: number;
  name: string;
  description: string;
  steps: {
    id: number;
    assignee: string;
    tasks: number[];
  }[];
}

interface WorkflowProgress {
  id: number;
  workflowId: number;
  name: string;
  currentStep: number;
  steps: {
    id: number;
    assignee: string;
    status: string;
    data: Record<string, string>;
  }[];
}

const WorkflowDashboard: React.FC = () => {
  const [inProgressWorkflows, setInProgressWorkflows] = useState<
    WorkflowProgress[]
  >([]);
  const [completedWorkflows, setCompletedWorkflows] = useState<
    WorkflowProgress[]
  >([]);
  const [selectedWorkflow, setSelectedWorkflow] =
    useState<WorkflowProgress | null>(null);
  const [selectedTemplate, setSelectedTemplate] =
    useState<WorkflowTemplate | null>(null);
  const [activeTab, setActiveTab] = useState<"in-progress" | "completed">(
    "in-progress"
  );

  useEffect(() => {
    const inProgress = workflowProgressData.filter(
      (w) => w.currentStep <= w.steps.length
    );
    const completed = workflowProgressData.filter(
      (w) => w.currentStep > w.steps.length
    );
    setInProgressWorkflows(inProgress);
    setCompletedWorkflows(completed);
  }, []);

  const handleWorkflowSelect = (workflow: WorkflowProgress) => {
    setSelectedWorkflow(workflow);
    const template = workflowTemplates.find(
      (t) => t.id === workflow.workflowId
    );
    if (template) {
      setSelectedTemplate(template);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Workflow Dashboard</h1>

      <div className="mb-8">
        <div className="flex mb-4">
          <button
            className={`px-4 py-2 mr-2 rounded ${
              activeTab === "in-progress"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setActiveTab("in-progress")}
          >
            In Progress
          </button>
          <button
            className={`px-4 py-2 rounded ${
              activeTab === "completed"
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setActiveTab("completed")}
          >
            Completed
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {(activeTab === "in-progress"
            ? inProgressWorkflows
            : completedWorkflows
          ).map((workflow) => (
            <div
              key={workflow.id}
              className={`border rounded-lg p-4 cursor-pointer hover:bg-gray-200 bg-white ${
                selectedWorkflow?.id === workflow.id
                  ? "border-blue-500 border-2"
                  : ""
              }`}
              onClick={() => handleWorkflowSelect(workflow)}
            >
              <h3 className="font-bold text-lg mb-2">{workflow.name}</h3>
              {activeTab === "in-progress" ? (
                <p>
                  Step {workflow.currentStep} of {workflow.steps.length}
                </p>
              ) : (
                <p>
                  Completed on:{" "}
                  {new Date(
                    workflow.steps[workflow.steps.length - 1].data
                      .completedAt || Date.now()
                  ).toLocaleDateString()}
                </p>
              )}
              <Link to={`/workflow-progress/${workflow.id}`}>
                {/* <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  View Progress
                </button> */}
              </Link>
            </div>
          ))}
        </div>
      </div>

      {selectedWorkflow && selectedTemplate && (
        <div className="mt-8 border-t pt-8">
          <h2 className="text-xl font-semibold mb-4">
            Workflow Details: {selectedWorkflow.name}
          </h2>

          <div className="flex mb-6 overflow-x-auto">
            {selectedTemplate.steps.map((step, index) => (
              <div
                key={step.id}
                className={`flex items-center ${
                  index === selectedTemplate.steps.length - 1 ? "" : "mr-2"
                }`}
              >
                <div
                  className={`rounded-full w-8 h-8 flex items-center justify-center ${
                    step.id < selectedWorkflow.currentStep
                      ? "bg-green-500"
                      : step.id === selectedWorkflow.currentStep
                      ? "bg-blue-500"
                      : "bg-gray-300"
                  } text-white`}
                >
                  {step.id < selectedWorkflow.currentStep ? "✓" : step.id}
                </div>
                <span className="mx-2">{step.assignee}</span>
                {index < selectedTemplate.steps.length - 1 && (
                  <span className="mx-2">→</span>
                )}
              </div>
            ))}
          </div>

          <div className="border rounded-lg p-4 bg-gray-50">
            <h3 className="font-bold text-lg mb-2">
              Current Step:{" "}
              {
                selectedTemplate.steps[selectedWorkflow.currentStep - 1]
                  .assignee
              }
            </h3>
            <p>
              Status:{" "}
              {selectedWorkflow.steps[selectedWorkflow.currentStep - 1].status}
            </p>
            <p className="mt-2">Description: {selectedTemplate.description}</p>
            <Link to={`/workflow-progress/${selectedWorkflow.id}`}>
              <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                View Detailed Progress
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkflowDashboard;
