import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent as ReactKeyboardEvent,
} from "react";
import { MessageSquare, X, Send } from "lucide-react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useAssetApiClient } from "../../services/api/assetApiClient";
import SpeechToText from "../common/SpeechToText";

const MessageContent = ({ content }: { content: string }) => {
  // Function to convert markdown to HTML-styled content
  const processContent = (text: string) => {
    // Split into lines while preserving line breaks
    const lines = text.split(/\n/).map((line) => {
      // Process the line content first to handle inline formatting
      const processInlineMarkdown = (content: string) => {
        return content.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
      };

      // Check if it's a numbered list item
      const listMatch = line.match(/^(\d+)\.\s*(.*)/);
      if (listMatch) {
        return {
          type: "listItem",
          number: listMatch[1],
          // Process the content part for inline markdown
          content: processInlineMarkdown(listMatch[2].trim()),
        };
      }

      return {
        type: "text",
        content: processInlineMarkdown(line),
      };
    });

    return lines;
  };

  const segments = processContent(content);

  return (
    <div className="space-y-2 text-sm">
      {segments.map((segment, index) => {
        if (segment.type === "listItem") {
          return (
            <div key={index} className="flex items-start gap-2 ml-1">
              <span className="font-semibold min-w-[1.5rem]">
                {segment.number}.
              </span>
              <span
                className="flex-1"
                dangerouslySetInnerHTML={{ __html: segment.content }}
              />
            </div>
          );
        }
        return (
          <div
            key={index}
            className="whitespace-pre-line"
            dangerouslySetInnerHTML={{ __html: segment.content }}
          />
        );
      })}
    </div>
  );
};

interface ChatMessage {
  role: "user" | "assistant";
  content: string;
}

interface ChatBarProps {
  title: string;
  placeholder: string;
  assetId: string;
}

const ChatBar: React.FC<ChatBarProps> = ({ title, placeholder, assetId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const assetApiClient = useAssetApiClient();
  const speechToTextRef = useRef<{ stopListening: () => void }>(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleKeyDown = (e: ReactKeyboardEvent<HTMLTextAreaElement>) => {
    console.log("Key pressed:", e.key);
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!message.trim() || isLoading) return;

    if (speechToTextRef.current?.stopListening) {
      speechToTextRef.current.stopListening();
    }

    setIsLoading(true);
    const userMessage: ChatMessage = { role: "user", content: message };

    setChatHistory((prev) => [...prev, userMessage]);

    try {
      const aiResponse = await assetApiClient.getAIAssistantResponse(
        [...chatHistory, userMessage],
        assetId
      );
      const aiMessage: ChatMessage = { role: "assistant", content: aiResponse };
      setChatHistory((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error processing message:", error);
      const errorMessage: ChatMessage = {
        role: "assistant",
        content: "Sorry, I encountered an error while processing your request.",
      };
      setChatHistory((prev) => [...prev, errorMessage]);
    }

    setIsLoading(false);
    setMessage("");
  };

  return (
    <div className="fixed bottom-0 right-0 w-full sm:w-96 z-50">
      <div className="bg-white border-t border-l border-gray-200 shadow-lg rounded-tl-lg overflow-hidden">
        <div
          className="bg-gray-600 text-white p-3 flex justify-between items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center">
            <MessageSquare size={20} className="mr-2" />
            <span className="font-semibold">{title}</span>
          </div>
          {isOpen && (
            <Button
              variant="ghost"
              size="icon"
              className="text-white hover:bg-zinc-500"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <X size={20} />
            </Button>
          )}
        </div>
        {isOpen && (
          <>
            <div
              ref={chatContainerRef}
              className="max-h-96 overflow-y-auto p-3 space-y-4"
            >
              {chatHistory.map((msg, index) => (
                <div
                  key={index}
                  className={`p-3 rounded-lg ${
                    msg.role === "user"
                      ? "bg-blue-100 ml-8"
                      : "bg-gray-100 mr-8"
                  }`}
                >
                  <MessageContent content={msg.content} />
                </div>
              ))}
            </div>
            <form onSubmit={handleSubmit} className="p-3 border-t">
              <div className="flex items-start gap-2">
                <div className="flex-grow relative">
                  <SpeechToText
                    ref={speechToTextRef}
                    onTranscript={setMessage}
                    onListeningChange={setIsListening}
                    placeholder={placeholder}
                    initialText={message}
                    textareaClass="w-full resize-none px-3 py-2 pr-8 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    buttonClass={`absolute right-2 top-2 p-1 rounded-full transition-colors ${
                      isListening
                        ? "text-red-500 [animation:pulse_1s_infinite]"
                        : "text-gray-500"
                    }`}
                    textareaProps={{
                      rows: 2,
                      style: { minHeight: "44px", maxHeight: "60px" },
                      onKeyDown: (e) => {
                        console.log("Key event:", e.key);
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSubmit();
                        }
                      },
                      disabled: isLoading,
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  variant="outline"
                  className="border-gray-300 hover:bg-gray-100 h-[44px]"
                  disabled={isLoading}
                >
                  <Send size={18} />
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBar;
