import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LogIn, LogOut } from "lucide-react";
import { Button } from "../ui/button";

interface CheckInOutComponentProps {
  name: string;
  type: "asset" | "location";
}

const CheckInOutComponent: React.FC<CheckInOutComponentProps> = ({
  name,
  type,
}) => {
  const [isCheckActionOpen, setIsCheckActionOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState<string>("");
  const [isCheckedIn, setIsCheckedIn] = useState(type === "asset");

  const handleCheckAction = () => {
    if (type === "location") {
      if (isCheckedIn) {
        // For locations, check out immediately
        handleOppositeAction();
      } else {
        // For locations, open duration selection when checking in
        setIsCheckActionOpen(true);
      }
    } else {
      // type === "asset"
      if (isCheckedIn) {
        // For assets, open duration selection when checking out
        setIsCheckActionOpen(true);
      } else {
        // For assets, check in immediately
        handleOppositeAction();
      }
    }
    setSelectedDuration(""); // Reset selection
  };

  const handleOppositeAction = () => {
    console.log(
      `${isCheckedIn ? "Checked out from" : "Checked in to"} ${name}`
    );
    setIsCheckedIn(!isCheckedIn);
    // Add your check-in/out logic here
  };

  const handleDurationSelect = (duration: string) => {
    setSelectedDuration(duration);
    console.log(
      `${isCheckedIn ? "Checked out" : "Checked in to"} ${name} for ${duration}`
    );
    setIsCheckedIn(!isCheckedIn);
    // Add your check-in/out logic here
    setTimeout(() => {
      setIsCheckActionOpen(false);
      setSelectedDuration(""); // Reset selection after closing
    }, 500);
  };

  const durationOptions =
    type === "asset"
      ? ["Less than 1 hour", "1 - 4 hours", "4 - 8 hours", "More than 8 hours"]
      : [
          "Less than 15 minutes",
          "15 - 30 minutes",
          "30 - 60 minutes",
          "More than 60 minutes",
        ];

  const checkActionText = isCheckedIn ? "Check-out" : "Check-in";

  return (
    <div className="mb-8">
      <div className="flex flex-wrap gap-4 mb-4">
        <Button
          onClick={handleCheckAction}
          className="flex items-center justify-center px-6 py-3 border-2 border-gray-300 rounded-lg shadow-md hover:bg-blue-50 transition-colors duration-200"
          variant="outline"
        >
          {isCheckedIn ? (
            <LogOut className="mr-2" size={24} />
          ) : (
            <LogIn className="mr-2" size={24} />
          )}
          {checkActionText}
        </Button>
      </div>

      <AnimatePresence>
        {isCheckActionOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              duration: 0.1,
              ease: [0.1, 0.1, 0.1, 0.1],
            }}
            className="bg-white p-4 rounded-lg border-2 border-gray-300 overflow-hidden"
          >
            <h2 className="text-lg font-semibold mb-2">
              Planned {type === "asset" ? "check-out" : "visit"} duration:
            </h2>
            <div className="flex flex-wrap gap-2">
              {durationOptions.map((duration) => (
                <Button
                  key={duration}
                  onClick={() => handleDurationSelect(duration)}
                  variant={
                    selectedDuration === duration ? "secondary" : "outline"
                  }
                  className={`flex-grow basis-[calc(50%-0.25rem)] sm:basis-[calc(25%-0.375rem)] ${
                    selectedDuration === duration
                      ? "bg-gray-400 text-gray-800"
                      : ""
                  }`}
                >
                  {duration}
                </Button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CheckInOutComponent;
