import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import ChatBar from "../common/ChatBar";

interface AutomationData {
  nfcTagId: number;
  name: string;
  description: string;
  type: "automatic" | "semi-automatic";
  status: "active" | "inactive";
  lastRun: string;
  recipients: string[];
}

const automationData: AutomationData[] = [
  {
    nfcTagId: 8,
    name: "SW centrifugal pump emergency shut-down",
    description:
      "Initiates emergency shut-down procedure and notifies key personnel",
    type: "automatic",
    status: "active",
    lastRun: "2024-09-13 14:30:00",
    recipients: ["plant.manager@example.com", "safety.officer@example.com"],
  },
  {
    nfcTagId: 9,
    name: "SW centrifugal pump -- maintenance update",
    description: "Notifies key personnel about maintenance status",
    type: "semi-automatic",
    status: "active",
    lastRun: "2024-09-12 09:15:00",
    recipients: [
      "maintenance.lead@example.com",
      "operations.manager@example.com",
    ],
  },
];

const Automation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [automation, setAutomation] = useState<AutomationData | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>("");

  useEffect(() => {
    if (id) {
      const data = automationData.find(
        (auto) => auto.nfcTagId === parseInt(id)
      );
      if (data) {
        setAutomation(data);
      }
    }
  }, [id]);

  if (!automation) {
    return <div className="container mx-auto p-4">Automation not found</div>;
  }

  const triggerAutomation = () => {
    if (automation.type === "automatic") {
      console.log(`Triggering automatic workflow: ${automation.name}`);
      console.log(`Sending alerts to: ${automation.recipients.join(", ")}`);
      // Here you would typically call an API to trigger the automation
    } else {
      if (selectedStatus) {
        console.log(`Triggering semi-automatic workflow: ${automation.name}`);
        console.log(`Status update: ${selectedStatus}`);
        console.log(`Sending alerts to: ${automation.recipients.join(", ")}`);
        // Here you would typically call an API to trigger the automation with the selected status
        setSelectedStatus("");
      } else {
        console.log("Please select a status for the semi-automatic workflow");
      }
    }
  };

  const toggleActivation = () => {
    setAutomation((prev) => {
      if (prev) {
        return {
          ...prev,
          status: prev.status === "active" ? "inactive" : "active",
        };
      }
      return prev;
    });
  };

  return (
    <div className="container mx-auto p-4 pb-20">
      <h1 className="text-3xl font-bold mb-4">{automation.name}</h1>
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <p className="text-lg mb-2">
          <span className="font-semibold">Description:</span>{" "}
          {automation.description}
        </p>
        <p className="text-lg mb-2">
          <span className="font-semibold">Type:</span> {automation.type}
        </p>
        <p className="text-lg mb-2">
          <span className="font-semibold">Status:</span>{" "}
          <span
            className={`${
              automation.status === "active" ? "text-green-600" : "text-red-600"
            } font-semibold`}
          >
            {automation.status.charAt(0).toUpperCase() +
              automation.status.slice(1)}
          </span>
        </p>
        <p className="text-lg mb-2">
          <span className="font-semibold">Last Run:</span> {automation.lastRun}
        </p>
        <p className="text-lg mb-4">
          <span className="font-semibold">Recipients:</span>{" "}
          {automation.recipients.join(", ")}
        </p>

        {automation.type === "semi-automatic" && (
          <div className="mb-4">
            <Select onValueChange={setSelectedStatus}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select status update" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="complete">
                  Maintenance complete; all clear
                </SelectItem>
                <SelectItem value="in-progress">
                  Maintenance still in progress; ETA less than 1 hour
                </SelectItem>
                <SelectItem value="offline">
                  Offline until further notice
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        )}

        <div className="flex space-x-4">
          <Button
            onClick={triggerAutomation}
            disabled={
              automation.status === "inactive" ||
              (automation.type === "semi-automatic" && !selectedStatus)
            }
          >
            Trigger Workflow
          </Button>
          <Button onClick={toggleActivation} variant="outline">
            {automation.status === "active" ? "Deactivate" : "Activate"}
          </Button>
        </div>
      </div>

      <ChatBar
        title="Automation Assistant"
        placeholder="Ask about this automation workflow"
      />
    </div>
  );
};

export default Automation;
