import React from "react";
import { Button } from "../ui/button";
import { Slider } from "../ui/slider";
import { Toggle } from "../ui/toggle";
import { Droplet, Square } from "lucide-react";

interface AnnotationSubMenuProps {
  tool: "select" | "text" | "arrow" | "rectangle" | "edit" | null;
  color: string;
  opacity: number;
  hasBackground: boolean;
  onColorChange: (color: string) => void;
  onOpacityChange: (opacity: number) => void;
  onBackgroundToggle: () => void;
}

const AnnotationSubMenu: React.FC<AnnotationSubMenuProps> = ({
  tool,
  color,
  opacity,
  hasBackground,
  onColorChange,
  onOpacityChange,
  onBackgroundToggle,
}) => {
  if (!tool) return null;

  const colors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
  ];

  return (
    <div className="bg-gray-100 rounded-full p-2 shadow-md flex items-center space-x-2 h-12">
      <div className="flex space-x-1">
        {colors.map((c) => (
          <Button
            key={c}
            size="sm"
            variant="ghost"
            className={`w-6 h-6 p-0 rounded-full ${
              color === c ? "ring-2 ring-offset-2 ring-blue-500" : ""
            }`}
            style={{ backgroundColor: c }}
            onClick={() => onColorChange(c)}
          />
        ))}
      </div>
      <div className="flex items-center space-x-2">
        <Droplet size={16} className="text-gray-600" />
        <Slider
          min={0}
          max={1}
          step={0.1}
          value={[opacity]}
          onValueChange={(value) => onOpacityChange(value[0])}
          className="w-24"
        />
      </div>
      <div className="w-8 flex justify-center">
        {tool === "text" && (
          <Toggle
            pressed={hasBackground}
            onPressedChange={onBackgroundToggle}
            size="sm"
            aria-label="Toggle text background"
            className="h-8 w-8 p-0"
          >
            <Square size={16} className="text-gray-600" />
          </Toggle>
        )}
      </div>
    </div>
  );
};

export default AnnotationSubMenu;
