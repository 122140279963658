import React, { useState, useEffect } from "react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import workflowsData from "../../../data/workflows/workflows.json";

interface Field {
  name: string;
  type: string;
  editable: boolean;
}

interface Step {
  id: number;
  name: string;
  role: string;
  fields: Field[];
}

interface Workflow {
  id: number;
  name: string;
  description: string;
  steps: Step[];
}

const WorkflowManagement: React.FC = () => {
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow | null>(null);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [currentStep, setCurrentStep] = useState<Step | null>(null);
  const [stepName, setStepName] = useState("");
  const [stepRole, setStepRole] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState<string>("text");

  useEffect(() => {
    setWorkflows(workflowsData);
  }, []);

  const addWorkflow = () => {
    if (workflowName && workflowDescription) {
      const newWorkflow: Workflow = {
        id: Date.now(),
        name: workflowName,
        description: workflowDescription,
        steps: [],
      };
      setWorkflows([...workflows, newWorkflow]);
      setCurrentWorkflow(newWorkflow);
      setWorkflowName("");
      setWorkflowDescription("");
    }
  };

  const addStep = () => {
    if (currentWorkflow && stepName && stepRole) {
      const newStep: Step = {
        id: Date.now(),
        name: stepName,
        role: stepRole,
        fields: [],
      };
      const updatedWorkflow = {
        ...currentWorkflow,
        steps: [...currentWorkflow.steps, newStep],
      };
      setWorkflows(
        workflows.map((w) =>
          w.id === currentWorkflow.id ? updatedWorkflow : w
        )
      );
      setCurrentWorkflow(updatedWorkflow);
      setCurrentStep(newStep);
      setStepName("");
      setStepRole("");
    }
  };

  const addField = () => {
    if (currentStep && fieldName && fieldType) {
      const newField: Field = {
        name: fieldName,
        type: fieldType,
        editable: true,
      };
      const updatedStep = {
        ...currentStep,
        fields: [...currentStep.fields, newField],
      };
      const updatedWorkflow = {
        ...currentWorkflow!,
        steps: currentWorkflow!.steps.map((s) =>
          s.id === currentStep.id ? updatedStep : s
        ),
      };
      setWorkflows(
        workflows.map((w) =>
          w.id === currentWorkflow!.id ? updatedWorkflow : w
        )
      );
      setCurrentWorkflow(updatedWorkflow);
      setCurrentStep(updatedStep);
      setFieldName("");
      setFieldType("text");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Workflow Management</h1>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Create New Workflow</h2>
        <Input
          placeholder="Workflow Name"
          value={workflowName}
          onChange={(e) => setWorkflowName(e.target.value)}
          className="mb-2"
        />
        <Textarea
          placeholder="Workflow Description"
          value={workflowDescription}
          onChange={(e) => setWorkflowDescription(e.target.value)}
          className="mb-2"
        />
        <Button onClick={addWorkflow}>Create Workflow</Button>
      </div>

      {currentWorkflow && (
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Add Step to {currentWorkflow.name}
          </h2>
          <Input
            placeholder="Step Name"
            value={stepName}
            onChange={(e) => setStepName(e.target.value)}
            className="mb-2"
          />
          <Input
            placeholder="Role"
            value={stepRole}
            onChange={(e) => setStepRole(e.target.value)}
            className="mb-2"
          />
          <Button onClick={addStep}>Add Step</Button>
        </div>
      )}

      {currentStep && (
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Add Field to {currentStep.name}
          </h2>
          <Input
            placeholder="Field Name"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
            className="mb-2"
          />
          <Select value={fieldType} onValueChange={setFieldType}>
            <SelectTrigger>
              <SelectValue placeholder="Select field type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="text">Text</SelectItem>
              <SelectItem value="textarea">Textarea</SelectItem>
              <SelectItem value="select">Select</SelectItem>
              <SelectItem value="date">Date</SelectItem>
              <SelectItem value="checkbox">Checkbox</SelectItem>
            </SelectContent>
          </Select>
          <Button onClick={addField} className="mt-2">
            Add Field
          </Button>
        </div>
      )}

      <div>
        <h2 className="text-xl font-semibold mb-2">Existing Workflows</h2>
        {workflows.map((workflow) => (
          <div key={workflow.id} className="mb-4 p-4 border rounded">
            <h3 className="text-lg font-semibold">{workflow.name}</h3>
            <p>{workflow.description}</p>
            <h4 className="font-semibold mt-2">Steps:</h4>
            <ul className="list-disc pl-5">
              {workflow.steps.map((step) => (
                <li key={step.id}>
                  {step.name} - {step.role}
                  <ul className="list-circle pl-5">
                    {step.fields.map((field, index) => (
                      <li key={index}>
                        {field.name} ({field.type})
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
            <Button
              onClick={() => setCurrentWorkflow(workflow)}
              className="mt-2"
            >
              Edit Workflow
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowManagement;
