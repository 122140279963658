import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { CheckCircle, Clock, AlertTriangle } from "lucide-react";
import { mockDashboardData } from "./dashboardData";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Dashboard = ({ data = mockDashboardData }) => {
  // Compute statistics for solution statuses
  const solutionStats = useMemo(() => {
    const statuses = data.solutions.reduce((acc, solution) => {
      acc[solution.status] = (acc[solution.status] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(statuses).map(([name, value]) => ({
      name,
      value,
    }));
  }, [data.solutions]);

  // Compute checklist completion statistics
  const checklistStats = useMemo(() => {
    const total = data.checklistItems.length;
    const completed = data.completedChecklists.length;
    return [
      { name: "Completed", value: completed },
      { name: "Pending", value: Math.max(0, total - completed) },
    ];
  }, [data.checklistItems, data.completedChecklists]);

  // Format maintenance notes for timeline display
  const sortedNotes = useMemo(() => {
    return [...data.maintenanceNotes].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
  }, [data.maintenanceNotes]);

  // Compute media statistics
  const mediaStats = useMemo(
    () => [
      { name: "Documents", value: data.documents.length },
      { name: "Images", value: data.images.length },
      { name: "Videos", value: data.videos.length },
    ],
    [data.documents, data.images, data.videos]
  );

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius * 1.0999; // Increase this value to move labels further out
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="12"
      >
        {`${name} ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>

      {/* Key Asset Information */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Recent Maintenance</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              <p>
                <span className="font-semibold">Location:</span> {data.location}
              </p>
              <p>
                <span className="font-semibold">Type:</span> {data.assetType}
              </p>
              <p>
                <span className="font-semibold">Last Service:</span>{" "}
                {new Date(data.lastServiceDate).toLocaleDateString()}
              </p>
              <p>
                <span className="font-semibold">By:</span> {data.lastServiceBy}
              </p>
            </div>
          </CardContent>
        </Card>

        {/* Solution Status */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Solution Status</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={solutionStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {solutionStats.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* Checklist Progress */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Maintenance Progress</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={checklistStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {checklistStats.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* Media Overview */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Attached Media</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={mediaStats}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                >
                  {mediaStats.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Maintenance Timeline */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Maintenance Timeline</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {sortedNotes.map((note) => (
                <Alert key={note.id}>
                  <Clock className="h-4 w-4" />
                  <AlertTitle>
                    {new Date(note.date).toLocaleDateString()}
                  </AlertTitle>
                  <AlertDescription>
                    <span className="font-semibold">{note.technician}:</span>{" "}
                    {note.note}
                  </AlertDescription>
                </Alert>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Solutions List */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Active Solutions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {data.solutions.map((solution) => (
                <Alert
                  key={solution.id}
                  variant={
                    solution.status === "Pending approval"
                      ? "default"
                      : "success"
                  }
                >
                  {solution.status === "Pending approval" ? (
                    <Clock className="h-4 w-4" />
                  ) : (
                    <CheckCircle className="h-4 w-4" />
                  )}
                  <AlertTitle>{solution.problem}</AlertTitle>
                  <AlertDescription>{solution.solution}</AlertDescription>
                </Alert>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
