import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { PlusCircle } from "lucide-react";
import { Button } from "../ui/button";
import SpeechToText from "./SpeechToText";
import RatingAndComment from "./RatingAndComment";

interface Solution {
  id: number;
  problem: string;
  solution: string;
  status: string;
  rating?: "up" | "down";
  comment?: string;
}

interface SolutionsSectionProps {
  showSolutions: boolean;
  solutions: Solution[];
  showAddSolution: boolean;
  onAddSolution: () => void;
  onSubmitSolution: (problemText: string, solutionText: string) => void;
  onCancelAddSolution: () => void;
  onRateSolution: (
    solutionId: number,
    rating: "up" | "down",
    comment: string
  ) => void;
}

const SolutionsSection: React.FC<SolutionsSectionProps> = ({
  showSolutions,
  solutions,
  showAddSolution,
  onAddSolution,
  onSubmitSolution,
  onCancelAddSolution,
  onRateSolution,
}) => {
  const [newProblem, setNewProblem] = useState("");
  const [newSolution, setNewSolution] = useState("");

  const handleSave = () => {
    if (newProblem.trim() && newSolution.trim()) {
      onSubmitSolution(newProblem, newSolution);
      setNewProblem("");
      setNewSolution("");
    }
  };

  const handleCancel = () => {
    setNewProblem("");
    setNewSolution("");
    onCancelAddSolution();
  };

  const handleProblemTranscript = (transcript: string) => {
    setNewProblem(transcript);
  };

  const handleSolutionTranscript = (transcript: string) => {
    setNewSolution(transcript);
  };

  const sortedSolutions = [...solutions].sort((a, b) => b.id - a.id);

  return (
    <AnimatePresence>
      {showSolutions && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.1 }}
          className="bg-white p-4 rounded-lg border border-gray-300 mb-4"
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">Solutions</h3>
            <Button
              onClick={onAddSolution}
              className="bg-green-500 hover:bg-green-600 text-white"
            >
              <PlusCircle className="mr-2" size={16} />
              <span className="text-xs text-center text-white font-semibold hidden sm:block">
                Add New
              </span>
            </Button>
          </div>
          {showAddSolution && (
            <div className="mb-4">
              <SpeechToText
                onTranscript={handleProblemTranscript}
                placeholder="Enter problem description"
                textareaClass="w-full pr-10 mb-2 resize-none border border-gray-200 p-2"
                buttonClass="text-gray-400 hover:text-gray-600"
                initialText={newProblem}
              />
              <SpeechToText
                onTranscript={handleSolutionTranscript}
                placeholder="Enter solution"
                textareaClass="w-full pr-10 mb-2 resize-none border border-gray-200 p-2"
                buttonClass="text-gray-400 hover:text-gray-600"
                initialText={newSolution}
              />
              <div className="flex justify-end space-x-2">
                <Button onClick={handleCancel} variant="outline">
                  Cancel
                </Button>
                <Button
                  onClick={handleSave}
                  className="bg-blue-500 hover:bg-blue-600 text-white"
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          <ul className="space-y-4">
            {sortedSolutions.map((solution: Solution) => (
              <li key={solution.id} className="border-b border-gray-200 pb-4">
                <p className="font-semibold">Problem: {solution.problem}</p>
                <p>Solution: {solution.solution}</p>
                <p
                  className={`text-sm ${
                    solution.status === "Approved"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  Status: {solution.status}
                </p>
                {/* <div className="mt-2">
                  <RatingAndComment
                    onSubmit={(rating, comment) =>
                      onRateSolution(solution.id, rating, comment)
                    }
                    initialRating={solution.rating}
                    initialComment={solution.comment}
                  />
                </div> */}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SolutionsSection;
