import React, { useEffect, useRef, useState, useCallback } from "react";
import * as atlas from "azure-maps-control";
import * as drawingTools from "azure-maps-drawing-tools";
import "azure-maps-control/dist/atlas.min.css";
import "azure-maps-drawing-tools/dist/atlas-drawing.min.css";

interface AzureMapProps {
  latitude: number;
  longitude: number;
  geofence?: atlas.data.Polygon;
  isEditable?: boolean;
  onGeofenceChange?: (geofence: atlas.data.Polygon | null) => void;
  pinColor?: "blue" | "red"; // New prop to specify pin color
  title?: string; // New prop to specify pin title
}

const AzureMap: React.FC<AzureMapProps> = ({
  latitude,
  longitude,
  geofence,
  isEditable = false,
  onGeofenceChange,
  pinColor = "red", // Default to red for locations
  title = "Location", // Default title
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<atlas.Map | null>(null);
  const drawingManager = useRef<drawingTools.drawing.DrawingManager | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);
  const [debugMessage, setDebugMessage] = useState<string>("");
  const [isMapReady, setIsMapReady] = useState(false);
  const [mapError, setMapError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const pinDataSource = useRef<atlas.source.DataSource | null>(null);
  const isPinAdded = useRef(false);

  const initializeMap = useCallback(() => {
    if (mapRef.current && !mapInstance.current) {
      try {
        mapInstance.current = new atlas.Map(mapRef.current, {
          center: [longitude, latitude],
          zoom: 14,
          view: "Auto",
          authOptions: {
            authType: atlas.AuthenticationType.subscriptionKey,
            subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY || "",
          },
          style: "road",
          renderWorldCopies: false,
        });

        mapInstance.current.events.add("ready", () => {
          console.log("Map is ready");
          setIsMapReady(true);
          if (mapInstance.current) {
            const datasource = new atlas.source.DataSource();
            mapInstance.current.sources.add(datasource);
            pinDataSource.current = new atlas.source.DataSource();
            mapInstance.current.sources.add(pinDataSource.current);

            // Add a point feature for the location pin
            if (!isPinAdded.current && pinDataSource.current) {
              pinDataSource.current.add(
                new atlas.data.Feature(
                  new atlas.data.Point([longitude, latitude]),
                  { title: title }
                )
              );
              isPinAdded.current = true;
            }

            // Add a symbol layer for the location pin
            mapInstance.current.layers.add(
              new atlas.layer.SymbolLayer(pinDataSource.current, null, {
                iconOptions: {
                  image: `pin-round-${pinColor}`,
                  anchor: "center",
                  allowOverlap: true,
                },
                textOptions: {
                  textField: ["get", "title"],
                  offset: [0, 1.2],
                },
              })
            );

            if (isEditable) {
              drawingManager.current = new drawingTools.drawing.DrawingManager(
                mapInstance.current,
                {
                  mode: "idle",
                  interactionType: "freehand",
                  shapeDraggingEnabled: true,
                }
              );

              console.log("DrawingManager initialized");
              setDebugMessage("Map and DrawingManager initialized");

              if (geofence) {
                datasource.add(new atlas.data.Feature(geofence));
                mapInstance.current.layers.add(
                  new atlas.layer.PolygonLayer(datasource, null, {
                    fillColor: "rgba(150, 80, 200, 0.3)",
                    fillOpacity: 0.5,
                  })
                );
              }

              mapInstance.current.events.add(
                "drawingcomplete",
                drawingManager.current,
                handleDrawingComplete
              );

              mapInstance.current.events.add(
                "drawingmodechanged",
                drawingManager.current,
                handleDrawingModeChanged
              );
            }
          }
        });

        mapInstance.current.events.add("error", (e: atlas.ErrorEvent) => {
          console.error("Map error:", e.error);
          setMapError(`Map error: ${e.error.message}`);
        });
      } catch (error) {
        console.error("Error initializing map:", error);
        setMapError(`Error initializing map: ${error.message}`);
      }
    }
  }, [latitude, longitude, geofence, isEditable, pinColor, title]);

  const handleDrawingComplete = useCallback(
    (e: drawingTools.drawing.DrawingEndedEventArgs) => {
      console.log("Drawing complete event:", e);
      setDebugMessage("Drawing complete");

      if (e.data && e.data.geometry) {
        console.log("Geometry type:", e.data.geometry.type);
        console.log("Full geometry object:", JSON.stringify(e.data.geometry));

        if (e.data.geometry instanceof atlas.data.Polygon) {
          console.log("Geometry is a Polygon");
          if (onGeofenceChange) {
            onGeofenceChange(e.data.geometry);
          }
        } else if (e.data.geometry.type === "Polygon") {
          console.log("Geometry is a GeoJSON Polygon");
          const polygon = new atlas.data.Polygon(e.data.geometry.coordinates);
          if (onGeofenceChange) {
            onGeofenceChange(polygon);
          }
        } else {
          console.error("Unexpected geometry type:", e.data.geometry.type);
        }
      } else {
        console.error("No geometry data in drawing complete event");
      }

      if (drawingManager.current) {
        drawingManager.current.setOptions({ mode: "idle" });
      }
      setIsEditing(false);
    },
    [onGeofenceChange]
  );

  const handleDrawingModeChanged = useCallback(
    (e: drawingTools.drawing.DrawingModeChangedEventArgs) => {
      console.log("Drawing mode changed", e);
      setDebugMessage(`Drawing mode changed: ${e.mode}`);
    },
    []
  );

  useEffect(() => {
    initializeMap();
    setIsLoading(false);

    return () => {
      if (mapInstance.current) {
        if (drawingManager.current) {
          mapInstance.current.events.remove(
            "drawingcomplete",
            drawingManager.current,
            handleDrawingComplete
          );
          mapInstance.current.events.remove(
            "drawingmodechanged",
            drawingManager.current,
            handleDrawingModeChanged
          );
          drawingManager.current.dispose();
        }
        mapInstance.current.dispose();
      }
      mapInstance.current = null;
      drawingManager.current = null;
      setIsMapReady(false);
      setIsEditing(false);
    };
  }, [initializeMap, handleDrawingComplete, handleDrawingModeChanged]);

  const toggleEditing = useCallback(() => {
    if (!isMapReady || !drawingManager.current || !mapInstance.current) {
      console.log("Map or DrawingManager not ready");
      setDebugMessage("Map or DrawingManager not ready");
      return;
    }

    const newMode = isEditing ? "idle" : "draw-polygon";
    drawingManager.current.setOptions({ mode: newMode });
    console.log(`Toggled editing mode to: ${newMode}`);
    setDebugMessage(`Toggled editing mode to: ${newMode}`);
    setIsEditing(!isEditing);

    if (!isEditing && geofence) {
      drawingManager.current.clear();
    } else if (isEditing) {
      const shapes = drawingManager.current.getSource().getShapes();
      if (shapes.length > 0) {
        const currentGeofence = shapes[0] as atlas.data.Polygon;
        if (currentGeofence && onGeofenceChange) {
          onGeofenceChange(currentGeofence);
        }
      }
    }

    mapInstance.current.resize();
  }, [isEditing, geofence, onGeofenceChange, isMapReady]);

  const handleClearGeofence = useCallback(() => {
    if (!isMapReady || !drawingManager.current) {
      console.log("Map or DrawingManager not ready");
      setDebugMessage("Map or DrawingManager not ready");
      return;
    }

    drawingManager.current.clear();
    console.log("Cleared geofence");
    setDebugMessage("Cleared geofence");
    if (onGeofenceChange) {
      onGeofenceChange(null);
    }
    drawingManager.current.setOptions({ mode: "idle" });
    setIsEditing(false);
  }, [onGeofenceChange, isMapReady]);

  return (
    <div className="relative">
      {isLoading && <div className="text-center">Loading map...</div>}
      <div ref={mapRef} style={{ width: "100%", height: "400px" }}></div>
      {mapError && <div className="text-red-500 mt-2">{mapError}</div>}
      {isEditable && isMapReady && (
        <div className="absolute top-2 left-2 bg-white p-2 rounded shadow">
          <button
            onClick={toggleEditing}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
          >
            {isEditing ? "Finish Editing" : "Edit Geofence"}
          </button>
          <button
            onClick={handleClearGeofence}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Clear Geofence
          </button>
        </div>
      )}
      {/* <div className="mt-2 p-2 bg-gray-100 rounded">Debug: {debugMessage}</div> */}
    </div>
  );
};

export default AzureMap;
