import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  useAuth,
} from "@clerk/clerk-react";
import Navigation from "./components/layouts/Navigation";
import Dashboard from "./components/pages/Dashboard";
import Locations from "./components/features/locations/Locations";
import Location from "./components/features/locations/Location";
import Assets from "./components/features/assets/Assets";
import Asset from "./components/features/assets/Asset";
import Automation from "./components/pages/Automation";
import PhotoCaptureAndAnnotation from "./components/features/assets/PhotoCaptureAndAnnotation";
import NFCTagHandler from "./components/features/nfc/NFCTagHandler";
import Admin from "./components/pages/AdminPage";
import WorkflowManagement from "./components/features/workflows/WorkflowManagement";
import AddWorkflow from "./components/features/workflows/AddWorkflow";
import WorkflowComponent from "./components/features/workflows/WorkflowComponent";
import WorkflowDashboard from "./components/features/workflows/WorkflowDashboard";
import WorkflowProgress from "./components/features/workflows/WorkflowProgress";
import ImageAnnotationPage from "./components/pages/ImageAnnotationPage";
import Map from "./components/pages/Map";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import SetupProfile from "./components/SetupProfile";
import SetupOrganization from "./components/SetupOrganization";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const SessionHandler: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoaded, isSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate("/sign-in");
    }
  }, [isLoaded, isSignedIn, navigate]);

  // if (!isLoaded) {
  //   return <div>Loading...</div>;
  // }

  return <>{children}</>;
};

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({
  element,
}) => (
  <SessionHandler>
    <SignedIn>{element}</SignedIn>
    <SignedOut>
      <Navigate to="/sign-in" replace />
    </SignedOut>
  </SessionHandler>
);

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex-grow flex items-center justify-center p-4 sm:p-6 md:p-8">
    <div className="w-full max-w-md">{children}</div>
  </div>
);

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div
      className={`flex-grow transition-all duration-300 ${
        isSidebarOpen ? "ml-64" : "ml-16"
      }`}
    >
      <div className="container mx-auto p-4">{children}</div>
    </div>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const isAuthPage = ["/sign-in", "/sign-up"].includes(location.pathname);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Navigation isAuthPage={isAuthPage} />
      <main className="flex-grow flex flex-col mt-[73px]">
        {isAuthPage ? (
          <AuthLayout>
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
            </Routes>
          </AuthLayout>
        ) : (
          <MainLayout>
            <Routes>
              <Route
                path="/setup-profile"
                element={<ProtectedRoute element={<SetupProfile />} />}
              />
              <Route
                path="/setup-organization"
                element={<ProtectedRoute element={<SetupOrganization />} />}
              />
              <Route
                path="/"
                element={<ProtectedRoute element={<Dashboard />} />}
              />
              <Route
                path="/locations"
                element={<ProtectedRoute element={<Locations />} />}
              />
              <Route
                path="/location/:id"
                element={<ProtectedRoute element={<Location />} />}
              />
              <Route
                path="/assets"
                element={<ProtectedRoute element={<Assets />} />}
              />
              <Route
                path="/asset/:id"
                element={<ProtectedRoute element={<Asset />} />}
              />
              <Route
                path="/automation/:id"
                element={<ProtectedRoute element={<Automation />} />}
              />
              <Route
                path="/add-asset"
                element={
                  <ProtectedRoute element={<PhotoCaptureAndAnnotation />} />
                }
              />
              <Route
                path="/nfc"
                element={<ProtectedRoute element={<NFCTagHandler />} />}
              />
              <Route
                path="/admin"
                element={<ProtectedRoute element={<Admin />} />}
              />
              <Route
                path="/admin/workflows"
                element={<ProtectedRoute element={<WorkflowManagement />} />}
              />
              <Route
                path="/admin/add-workflow"
                element={<ProtectedRoute element={<WorkflowComponent />} />}
              />
              <Route
                path="/admin/edit-workflow/:id"
                element={<ProtectedRoute element={<WorkflowComponent />} />}
              />
              <Route
                path="/workflows"
                element={<ProtectedRoute element={<WorkflowDashboard />} />}
              />
              <Route
                path="/workflow-progress/:id"
                element={<ProtectedRoute element={<WorkflowProgress />} />}
              />
              <Route
                path="/annotate/:assetId/:imageId"
                element={<ProtectedRoute element={<ImageAnnotationPage />} />}
              />
              <Route
                path="/map"
                element={<ProtectedRoute element={<Map />} />}
              />
            </Routes>
          </MainLayout>
        )}
      </main>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => window.history.pushState({}, "", to)}
    >
      <Router>
        <AppContent />
      </Router>
    </ClerkProvider>
  );
};

export default App;
