import React, { useState, useEffect } from "react";
import GlobalMap from "../common/GlobalMap";
import { useAssetApiClient } from "../../services/api/assetApiClient";
import { Asset } from "../../interfaces/asset";
import { Location } from "../../interfaces/location";
import { Button } from "../ui/button";
import { MapPin, Box, Fence } from "lucide-react";

const Map: React.FC = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [showAssets, setShowAssets] = useState(true);
  const [showLocations, setShowLocations] = useState(true);
  const [showGeofences, setShowGeofences] = useState(true);
  const [hoveredItem, setHoveredItem] = useState<Asset | Location | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Add this line
  const assetApiClient = useAssetApiClient();

  useEffect(() => {
    const fetchData = async () => {
      const orgId = "ABC123"; // Replace with actual orgId
      const fetchedAssets = await assetApiClient.getAllAssets(orgId);
      const fetchedLocations = await assetApiClient.getAllLocations(orgId);
      setAssets(fetchedAssets);
      setLocations(fetchedLocations);
    };
    fetchData();

    // Add an event listener to track sidebar state
    const handleSidebarChange = (e: CustomEvent) =>
      setIsSidebarOpen(e.detail.isOpen);
    window.addEventListener(
      "sidebarStateChange",
      handleSidebarChange as EventListener
    );

    return () => {
      window.removeEventListener(
        "sidebarStateChange",
        handleSidebarChange as EventListener
      );
    };
  }, []);

  const handlePinHover = (item: Asset | Location) => {
    setHoveredItem(item);
  };

  return (
    <div
      className={`fixed top-[73px] bottom-0 left-0 right-0 ${
        isSidebarOpen ? "ml-64" : "ml-14"
      }`}
    >
      <GlobalMap
        assets={assets}
        locations={locations}
        showAssets={showAssets}
        showLocations={showLocations}
        showGeofences={showGeofences}
        onPinHover={handlePinHover}
      />
      <div className="absolute top-4 left-4 bg-white p-4 rounded shadow-md z-10">
        <Button
          onClick={() => setShowAssets(!showAssets)}
          className={`mb-2 ${showAssets ? "bg-blue-500" : "bg-gray-300"}`}
        >
          <Box className="mr-2" /> Assets
        </Button>
        <Button
          onClick={() => setShowLocations(!showLocations)}
          className={`mb-2 ${showLocations ? "bg-blue-500" : "bg-gray-300"}`}
        >
          <MapPin className="mr-2" /> Locations
        </Button>
        <Button
          onClick={() => setShowGeofences(!showGeofences)}
          className={`${showGeofences ? "bg-blue-500" : "bg-gray-300"}`}
        >
          <Fence className="mr-2" /> Geofences
        </Button>
      </div>
      {hoveredItem && (
        <div className="absolute bottom-4 left-4 bg-white p-4 rounded shadow-md z-10">
          <h3 className="font-bold">{hoveredItem.name}</h3>
          <p>
            {"type" in hoveredItem && hoveredItem.type === "location"
              ? "Location"
              : "Asset"}
          </p>
          {"lastServiceDate" in hoveredItem && (
            <p>Last serviced: {hoveredItem.lastServiceDate}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Map;
