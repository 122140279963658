import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";

interface ChecklistItem {
  id: number;
  name: string;
  questions: string[];
}

interface CompletedChecklist {
  id: number;
  checklistId: number;
  name: string;
  completedBy: string;
  completedAt: string;
  answers: boolean[];
}

interface UserInfo {
  id: string | null;
  fullName: string | null;
  username: string | null;
}

interface ChecklistCompletionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  checklist: ChecklistItem | null;
  onSave: (completedChecklist: CompletedChecklist) => void;
  userInfo: {
    id: string | null;
    fullName: string | null;
    username: string | null;
  } | null;
}

const ChecklistCompletionDialog: React.FC<ChecklistCompletionDialogProps> = ({
  isOpen,
  onClose,
  checklist,
  onSave,
  userInfo,
}) => {
  const [answers, setAnswers] = useState<boolean[]>([]);

  useEffect(() => {
    if (checklist) {
      setAnswers(new Array(checklist.questions.length).fill(false));
    }
  }, [checklist]);

  const handleCheckboxChange = (index: number) => {
    const newAnswers = [...answers];
    newAnswers[index] = !newAnswers[index];
    setAnswers(newAnswers);
  };

  const handleSave = () => {
    if (!checklist) {
      console.error("Checklist is null");
      return;
    }

    const allAnswersChecked = answers.every((answer) => answer);
    if (!allAnswersChecked) {
      console.error("Not all answers are checked");
      return;
    }

    const completedBy = userInfo
      ? userInfo.fullName ||
        userInfo.username ||
        userInfo.id ||
        "Anonymous User"
      : "Anonymous User";

    const completedChecklist: CompletedChecklist = {
      id: Date.now(),
      checklistId: checklist.id,
      name: checklist.name,
      completedBy: completedBy,
      completedAt: new Date().toISOString(),
      answers: answers,
    };

    console.log("Saving completed checklist:", completedChecklist);
    onSave(completedChecklist);
    onClose();
  };

  const isCompleted = answers.every((answer) => answer);

  if (!checklist) {
    // console.log("Checklist is null, not rendering dialog");
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold mb-4">
            {checklist.name}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {checklist.questions.map((question, index) => (
            <div key={index} className="flex items-center space-x-2">
              <Checkbox
                id={`question-${index}`}
                checked={answers[index]}
                onCheckedChange={() => handleCheckboxChange(index)}
              />
              <label htmlFor={`question-${index}`} className="text-sm">
                {question}
              </label>
            </div>
          ))}
        </div>
        <DialogFooter className="mt-6">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            className="mr-2"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSave}
            disabled={!isCompleted}
            className="bg-blue-500 hover:bg-blue-600 text-white"
          >
            Complete Checklist
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ChecklistCompletionDialog;
