import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAssetApiClient } from "../../../services/api/assetApiClient";
import { Location } from "../../../interfaces/location";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";

const Locations: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const assetApiClient = useAssetApiClient();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLoading(true);
        const response = await assetApiClient.getAllLocations();

        if (Array.isArray(response)) {
          setLocations(response);
        } else {
          console.error("Unexpected API response format:", response);
          setError("Received unexpected data format from the server");
        }
      } catch (err) {
        console.error("Error fetching locations:", err);
        setError("Failed to fetch locations. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-lg">Loading locations...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Locations</h1>
        <Link to="/add-location">
          <Button className="bg-blue-500 hover:bg-blue-600 text-white">
            Add Location
          </Button>
        </Link>
      </div>

      <div className="mb-4">
        <div className="mb-4 flex space-x-2">
          <Input
            type="text"
            placeholder="Search locations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow"
          />
          <Button onClick={() => setSearchTerm("")}>Clear</Button>
        </div>
      </div>

      {locations.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">No locations found.</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredLocations.map((location) => (
            <Link
              to={`/location/${location.displayId}`}
              key={location.id}
              className="block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
            >
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{location.name}</h2>
                <p className="text-gray-600 text-sm">{location.description}</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Locations;
